import { Box, Button, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { Assets, PigogoButton, PigogoSmallButton, PigogoTextField, theme } from 'components';
import { useCreateUserMutation } from '../../redux/api/mutations/userMutations';
import { useAppDispatch } from '../../redux/hooks';
import { setLoad } from '../../redux/slices/layoutSlice';
import { useIonRouter } from '@ionic/react';
import { emailValid } from '../../utils/validation';
import {
  setAccessToken,
  setClosedAlert,
  setFirstLogin,
  setRefreshToken,
  setSeenAlert,
  setSessionLoggedIn,
} from '../../redux/slices/authenticationSlice';
import { isWeb } from '../../utils/device';
import { Link } from 'react-router-dom';
import { createGenericEvent } from '../../react-gtm-helpers';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  borderRadius: '16px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  justifyContent: 'center',
  background: theme.palette.error.contrastText,

  [theme.breakpoints.up('md')]: {
    padding: '32px',
    width: '408px',
  },
  [theme.breakpoints.up('extraLg')]: {
    padding: '32px',
    width: '436px',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    padding: '24px',
    width: '408px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '100%',
  },
}));

interface Props {
  header: string;
  heroSection?: boolean;
  headerDescription?: string;
  onClickFacebook: () => void;
  onClickGoogle: () => void;
  onClickSignIn?: () => void;
}

const RegisterFloatingZone: FC<Props> = ({
  header,
  headerDescription,
  onClickFacebook,
  onClickSignIn,
  onClickGoogle,
  heroSection,
}) => {
  const route = useIonRouter();
  const dispatch = useAppDispatch();

  const web = isWeb(); //const web = false;

  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [createUser, { isLoading, isError, error }] = useCreateUserMutation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      if (err.status !== 400) {
        setEmailError(true);
        setEmailErrorMsg('Υπάρχει ήδη εγγεγραμμένος χρήστης με αυτό το email! Χρησιμοποίησε τη φόρμα σύνδεσης.');
      } else if (err.status === 400) {
        setEmailError(true);
        setEmailErrorMsg('Το email δεν φαίνεται να είναι σωστό.');
      }
    }
  }, [dispatch, error, isError]);

  useEffect(() => {
    dispatch(setLoad(isLoading));
  }, [isLoading, dispatch]);

  const registerRequest = async () => {
    if (web) {
      const csrf: any = await fetch(process.env['REACT_APP_API_XSRF_TOKEN_URL'] ?? '', {
        credentials: 'include',
      });
    }
    const registerResponce: any = await createUser({ body: { email, password, signup_agree: true } });
    if (registerResponce && registerResponce.error) {
      return;
    }
    setTimeout(() => {
      if (registerResponce.data.resource_created) {
        dispatch(setFirstLogin({ isFirst: true }));
        dispatch(setSeenAlert({ seenAlert: false }));
        dispatch(setClosedAlert({ closedAlert: false }));

        if (registerResponce.data.uid) {
          createGenericEvent('Successful Registration', {
            'User ID': registerResponce.data.uid,
          });
        }
      }

      if (web) {
        if (registerResponce?.data) {
          dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: true }));
          dispatch(setSeenAlert({ seenAlert: false }));
        } else {
          dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
        }
      } else {
        dispatch(setAccessToken({ token: registerResponce?.data.access_token, rememberMe: true }));
        dispatch(setRefreshToken({ refresh_token: registerResponce?.data.refresh_token }));
      }
    }, 200);
  };

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!emailValid(email.trim()) && email.trim() !== '') {
      setEmailError(true);
      setEmailErrorMsg('Το email δεν φαίνεται να είναι σωστό.');
    } else if (!emailValid(email.trim()) && email.trim() === '') {
      setEmailError(true);
      setEmailErrorMsg('Το πεδίο είναι υποχρεωτικό.');
    } else if (emailValid(email.trim()) && email.trim().length > 100) {
      setEmailError(true);
      setEmailErrorMsg('Οι χαρακτήρες δεν πρέπει να ξεπερνούν τους 100.');
    } else {
      setEmailError(false);
    }

    if (password.length < 6 || password.length > 200) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (password.length >= 6 && password.length <= 200 && emailValid(email) && email.trim().length <= 100) {
      registerRequest();
    }
  };

  const extractHeader = () => {
    if (!mdDown) {
      return (
        <Box>
          <Typography variant="h4" component="h3" className="registerTitle" textAlign="center" color={'primary'}>
            Γίνε μέλος δωρεάν!
          </Typography>
        </Box>
      );
    } else if (mdDown && heroSection) {
      return (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" component="h3" color={'#313D53'}>
              Γίνε μέλος δωρεάν!
            </Typography>
            <Button onClick={onClickSignIn}>
              <Typography
                variant={'body2MediumM'}
                color="primary"
                sx={{ textDecoration: 'underline', textDecorationColor: '#313D53' }}
              >
                Σύνδεση
              </Typography>
            </Button>
          </Box>
        </Box>
      );
    } else
      return (
        <Box display={'flex'} flexDirection={'column'} gap={'4px'} textAlign={'center'} pb={3}>
          <Box>
            <Typography variant="h4">{header}</Typography>
          </Box>
          {headerDescription && (
            <Box sx={{ paddingInline: '24px' }}>
              <Typography variant="body2MediumM">{headerDescription}</Typography>
            </Box>
          )}
        </Box>
      );
  };

  const enterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <StyleBox>
      {extractHeader()}
      <Box>
        <Box sx={{ paddingTop: mdDown ? '20px' : '24px' }}>
          <PigogoTextField
            fullWidth
            value={email || null}
            label={'Εm\u200Aail'}
            size={'medium'}
            error={emailError}
            onFocus={() => {
              setEmailError(false);
            }}
            setValue={(value: any) => {
              setEmailError(false);
              setEmail(value);
            }}
            helperText={emailError && emailErrorMsg}
            onKeyDown={enterPress}
          />
        </Box>
      </Box>
      <Box sx={{ paddingTop: '16px' }}>
        <PigogoTextField
          label="Κωδικός"
          value={password}
          autoComplete={'one-time-code'}
          fullWidth={true}
          type={'password'}
          error={passwordError}
          onFocus={() => {
            setPasswordError(false);
          }}
          setValue={(value: any) => {
            setPasswordError(false);
            setPassword(value);
          }}
          helperText={
            passwordError
              ? password.trim() === ''
                ? 'Το πεδίο είναι υποχρεωτικό.'
                : password.trim().length > 200
                ? 'Οι χαρακτήρες δεν πρέπει να ξεπερνούν τους 200.'
                : 'Ο κωδικός πρέπει να είναι τουλάχιστον 6 χαρακτήρες.'
              : ''
          }
          onKeyDown={enterPress}
        />
      </Box>

      <Box
        gap={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{ paddingTop: '16px', paddingBottom: xsOnly ? '16px' : '24px' }}
      >
        <Box>
          <PigogoButton
            fullWidth
            variation="secondary"
            text={'Δημιουργία λογαριασμού'}
            onClick={(e: any) => handleSubmit(e)}
          />
        </Box>
        <Box>
          <Typography component="p" sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '1.71', color: '#313D53' }}>
            ή
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={heroSection ? 'row' : 'column'} gap={2} justifyContent={'center'}>
          <Box width={'178px'}>
            <PigogoSmallButton
              fullWidth
              variant={'outlined'}
              onClick={onClickFacebook}
              startIcon={<Assets.Facebook />}
              style={{ justifyContent: 'flex-start', borderRadius: '56px', height: '56px' }}
              typographyProps={{ variant: 'subtitle2' }}
              text={xsOnly && !heroSection ? 'Εγγραφή με Facebook' : 'Facebook'}
            />
          </Box>
          <Box width={'178px'}>
            <PigogoSmallButton
              fullWidth
              variant={'outlined'}
              onClick={onClickGoogle}
              startIcon={<Assets.Google />}
              style={{ justifyContent: 'flex-start', borderRadius: '56px', height: '56px' }}
              typographyProps={{ variant: 'subtitle2' }}
              text={xsOnly && !heroSection ? 'Εγγραφή με Google' : 'Google'}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={!mdDown ? 'flex' : 'inline-grid'}
        textAlign={'center'}
        justifyContent={'center'}
        flexWrap={'nowrap'}
        whiteSpace={!mdDown ? 'nowrap' : 'normal'}
        gap={!mdDown ? '6px' : '4px'}
      >
        <Typography variant="captionM" color={'#313D53'}>
          Δημιουργώντας λογαριασμό αποδέχεσαι τους
        </Typography>

        <Link className="btnLink btnLink--underline btnLink--small btnLink--xs" to={'/terms'}>
          Όρους χρήσης
        </Link>
      </Box>
    </StyleBox>
  );
};

export default RegisterFloatingZone;
