import React, { FC, useState } from 'react';
import { Box, Divider, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import HeaderCard from './HeaderCard';
import { useGetUserDataQuery, useGetUserPurchasesQuery } from '../../../redux/api/queries/userQueries';
import { useIonRouter } from '@ionic/react';
import InfoModal from './InfoModal';
import { ReactComponent as Info } from '../../../assets/svgs/info_light_color.svg';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRightColor.svg';
import { ReactComponent as All } from '../../../assets/svgs/illustrations/avatar/default_all.svg';
import { ReactComponent as Man } from '../../../assets/svgs/illustrations/avatar/default_boy.svg';
import { ReactComponent as Woman } from '../../../assets/svgs/illustrations/avatar/default_girl.svg';
import WarningMessage from './WarningMessage';
import { Link } from 'react-router-dom';

const Header = () => {
  const router = useIonRouter();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { data: userData, isLoading } = useGetUserDataQuery();
  const { data: purchasesData } = useGetUserPurchasesQuery({ limit: 4 });

  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [summary, setSummary] = useState<'Σε αναμονή' | 'Προς πληρωμή' | 'Πληρωμένες' | 'Έχεις συγκεντρώσει' | null>(
    null,
  );

  const handleInfoClick = (title: any) => {
    setSummary(title);
    setInfoModal(true);
  };

  const extractFirstColumnActions = (totalCount?: number) => {
    if (totalCount === 0) {
      return (
        <Link className="iconLink iconLink--small" to={'/stores'}>
          <Typography variant="caption" color={'#1D2532'}>
            Ξεκίνα τις αγορές σου
          </Typography>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              color: '#313D53',
            }}
          >
            <ChevronRight />
          </Box>
        </Link>
      );
    } else return undefined;
  };

  const userHasSetPaymentMethod = () => {
    if (userData?.payment_method_id == 1 && userData?.payment_bank_iban) {
      return true;
    } else if (userData?.payment_method_id == 3 && userData?.viva_wallet_phone_number) {
      return true;
    } else {
      return false;
    }
  };

  const extractSecondColumnActions = (
    methodId: any,
    purchases?: number | null,
    under_threshold?: boolean,
    due_purchases?: number | null,
  ) => {
    if (!userHasSetPaymentMethod() && !purchases) {
      return (
        <Link className="iconLink iconLink--small" to={'payments-options'}>
          <Typography variant="caption" color={'#1D2532'}>
            Στοιχεία πληρωμής
          </Typography>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              color: '#313D53',
            }}
          >
            <ChevronRight />
          </Box>
        </Link>
      );
    } else if (!userHasSetPaymentMethod()) {
      return (
        <Link className="iconLink iconLink--small" to={'payments-options'}>
          <Typography variant="caption" color={'#1D2532'}>
            Διάλεξε πώς θέλεις να πληρώνεσαι
          </Typography>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              color: '#313D53',
            }}
          >
            <ChevronRight />
          </Box>
        </Link>
      );
    } else if (under_threshold) {
      return (
        <Link className="iconLink iconLink--small" to={'payments-options'}>
          <Typography variant="caption" color={'#1D2532'}>
            Κάτω από το ελάχιστο ποσό πληρωμής σου
          </Typography>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              color: '#313D53',
            }}
          >
            <ChevronRight />
          </Box>
        </Link>
      );
    } else {
      return (
        <Typography variant="caption" component="p" color={'#6F7787'}>
          Επόμενη πληρωμή: {userData?.next_payment}
        </Typography>
      );
    }
  };

  const extractExtraField = (purchases?: number | null) => {
    if (purchases === 0) {
      return undefined;
    } else if (purchases === 1) {
      return '1 αγορά';
    } else {
      return `${purchases} αγορές`;
    }
  };

  const extractThirdColumnExtraField = (paidPurchases?: number, completedPurchases?: number) => {
    if (paidPurchases === 0 && completedPurchases === 0) {
      return undefined;
    } else {
      return `${paidPurchases} ${paidPurchases === 1 ? 'αγορά' : 'αγορές'} / ${completedPurchases} ${
        completedPurchases === 1 ? 'πληρωμή' : 'πληρωμές'
      }`;
    }
  };

  const headerCards = [
    {
      color: '#6F7787',
      title: 'Σε αναμονή',
      value: userData?.total_on_hold_cashback_amount,
      extrafield: extractExtraField(userData?.total_on_hold_purchases),
      action: extractFirstColumnActions(purchasesData?.totalCount),
    },
    {
      color: '#C07848',
      title: 'Προς πληρωμή',
      value: userData?.total_payment_due_cashback_amount,
      extrafield: extractExtraField(userData?.total_payment_due_purchases),
      action: extractSecondColumnActions(
        userData?.payment_method_id,
        purchasesData?.totalCount,
        userData?.is_under_payment_threshold,
        userData?.total_payment_due_purchases,
      ),
    },
    {
      color: '#3CA377',
      title: 'Πληρωμένες',
      value: userData?.total_paid_cashback_amount,
      extrafield: extractThirdColumnExtraField(userData?.total_paid_purchases, userData?.total_completed_payments),
    },
  ];

  const getIcon = () => {
    if (userData && userData.user_icon_id === 1) {
      return <Man />;
    } else if (userData && userData.user_icon_id === 2) {
      return <Woman />;
    } else {
      return <All />;
    }
  };

  return (
    <Box pt={tablet ? 4 : 0} display={'flex'} gap={mdDown ? 3 : 6} flexDirection={'column'}>
      <Box sx={{ display: mdDown ? 'block' : 'none', marginBottom: mdDown ? 1 : 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingTop: mdDown ? 2 : 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '64px', height: '64px' }}>
            {isLoading ? <Skeleton variant="circular" sx={{ p: 2 }} /> : getIcon()}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="body2" color={'#313D53'} component="h2">
              {userData?.first_name ? userData?.full_name : userData?.email}
            </Typography>
            <Typography variant="captionM" color={'#838B98'} component="p">
              {isLoading ? (
                <Skeleton sx={{ px: 6 }} />
              ) : (
                `επιστροφή: ${userData?.total_tracked_cashback_amount ?? '0,00 €'}`
              )}
            </Typography>
          </Box>
        </Box>
      </Box>

      {mdDown && <WarningMessage />}

      <Box border="2px solid #EAECEE" borderRadius="24px">
        <Box padding={'20px 24px 19px'} display="flex" justifyContent="space-between" borderBottom="2px solid #EAECEE">
          <Typography variant="large900" component="h3" color={'#1D2532'}>
            Σύνοψη
          </Typography>
          <Box display="flex" gap={2}>
            <Divider orientation="vertical" />
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="subtitle1SmallL" component="p" color="primary">
                Έχεις συγκεντρώσει
              </Typography>
              <Box display="flex" gap={1}>
                <Typography variant="largeB" component="p" color={'#1D2532'}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" sx={{ borderRadius: '16px' }} />
                  ) : (
                    userData?.total_tracked_cashback_amount
                  )}
                </Typography>
                <Box
                  height={'24px'}
                  width={'24px'}
                  display={'inline-flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ cursor: 'pointer', color: '#313D53' }}
                >
                  <Info onClick={() => handleInfoClick('Έχεις συγκεντρώσει')} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection={!mobile ? 'row' : 'column'} p={3} gap={2}>
          {!isLoading &&
            headerCards.map((card, index) => (
              <HeaderCard
                key={index}
                title={card.title}
                value={card.value}
                color={card.color}
                action={card.action}
                extraField={card.extrafield}
              />
            ))}
          {isLoading &&
            [1, 1, 1].map((item, index) => (
              <Skeleton sx={{ flex: '1 1 0', borderRadius: '16px' }} height="130px" variant="rectangular" key={index} />
            ))}
        </Box>
        {purchasesData && purchasesData?.totalCount > 0 && (
          <Box
            px={3}
            pb={4}
            gap={1}
            display="flex"
            flexDirection={mobile ? 'column' : 'row'}
            alignItems={mobile ? 'self-start' : 'center'}
          >
            <Typography variant="captionSB" color={'#313D53'}>
              Τελευταία καταγεγραμμένη αγορά:
            </Typography>
            <Typography variant="caption" color={'#6F7787'}>
              {' '}
              {userData?.last_tracked_purchase}
            </Typography>
          </Box>
        )}
      </Box>
      <InfoModal isOpen={infoModal} setOpen={setInfoModal} summary={summary} setSummary={setSummary} />
    </Box>
  );
};

export default Header;
