import { Box, Divider, Skeleton, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useIonViewDidLeave, useIonViewDidEnter } from '@ionic/react';
import { PigogoSwitch, theme } from 'components';
import { useLazyGetUserDataQuery } from '../../redux/api/queries/userQueries';
import { useUpdateUserMutation } from '../../redux/api/mutations/userMutations';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import WarningMessage from './Overview/WarningMessage';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../../assets/jpg/pigogo-socialshare.jpg';

const initialState = {
  purchase: false,
  newsletter: false,
  payment: false,
};

interface Props {
  unsubscribe?: boolean;
}

const ContactOptions: FC<Props> = ({ unsubscribe }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [updateUser, { isError: updateError, reset: resetUser }] = useUpdateUserMutation();

  const [purchase, setPurchase] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [payment, setPayment] = useState(false);

  const [leaving, setLeaving] = useState<boolean>(false);

  const [triggerUser, { data: userData, isLoading: userDataLoading }] = useLazyGetUserDataQuery();

  useIonViewDidEnter(async () => {
    setLeaving(false);
    const userData = await triggerUser();

    setPayment(userData.data?.notification_new_payment ?? false);
    setPurchase(userData.data?.notification_new_purchase ?? false);
    setNewsletter(userData.data?.notification_newsletter ?? false);
  });

  useEffect(() => {
    if (history.location.pathname.includes('user/contact-options')) {
      triggerUser().then((res) => {
        setPayment(res.data?.notification_new_payment ?? false);
        setPurchase(res.data?.notification_new_purchase ?? false);
        setNewsletter(res.data?.notification_newsletter ?? false);
      });
    }
  }, [history.location.pathname]);

  useIonViewDidLeave(() => {
    setLeaving(true);
  });

  useEffect(() => {
    if (leaving) {
      resetUser();
    }
  }, [leaving]);

  useEffect(() => {
    if (updateError) {
      setPayment(userData?.notification_new_payment ?? false);
      setPurchase(userData?.notification_new_purchase ?? false);
      setNewsletter(userData?.notification_newsletter ?? false);

      const params = { value: true, msg: 'Kάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [updateError]);

  const updateNotification = async (value: string, option: boolean) => {
    if (value === 'purchase') {
      updateUser({ notification_new_purchase: option });
      setPurchase(option);
    } else if (value === 'newsletter') {
      updateUser({ notification_newsletter: option });
      setNewsletter(option);
    } else if (value === 'payment') {
      updateUser({ notification_new_payment: option });
      setPayment(option);
    }
  };

  const getRow = (title: string, subtitle: string, value: string, switchBoolean: boolean) => {
    return (
      <Box width={'100%'} display={'flex'} gap={3} justifyContent={'space-between'}>
        {location.pathname === '/user/contact-options' && (
          <Helmet>
            <title>Ρυθμίσεις επικοινωνίας - Ο λογαριασμός μου | Pigogo</title>
            <meta
              name="description"
              content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
            />
            <meta name="og:name" content="Pigogo" />
            <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
            <meta name="og:image" content={PigogoSocialShare} />
          </Helmet>
        )}
        <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'flex-start'}>
          <Typography variant={'subtitle1'} component="h4" color={'#313D53'}>
            {userDataLoading ? <Skeleton /> : title}
          </Typography>
          <Typography color={'#313D53'} variant={'subtitle1SmallR'} component="p" align={'left'}>
            {userDataLoading ? <Skeleton /> : subtitle}
          </Typography>
        </Box>
        <Box display={'flex'} textAlign={'end'} alignItems={'flex-start'} pt={0.5}>
          {userDataLoading && <PigogoSwitch loading />}
          {!userDataLoading && (
            <PigogoSwitch
              inputProps={{ color: 'secondary' }}
              value={switchBoolean}
              setValue={() => updateNotification(value, !switchBoolean)}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box pb={mdDown ? '68px' : '264px'} pt={mdDown ? 5 : 0}>
      {userData?.user_warning_message && (
        <Box display="flex" flexDirection="column" mt={mdDown ? '-24px' : undefined} mb={mdDown ? 2 : 2}>
          <WarningMessage />
        </Box>
      )}
      <Box border="2px solid #EAECEE" borderRadius="24px">
        <Box textAlign="center">
          {(unsubscribe || !smDown) && (
            <>
              <Box display="flex" p={3} pb={unsubscribe ? 0 : 3}>
                <Typography variant="body1" component="h2" textAlign="start" color={'#1D2532'}>
                  Ρυθμίσεις επικοινωνίας
                </Typography>
              </Box>
              {unsubscribe && (
                <Box display="flex" p={3} pt={'4px'}>
                  <Typography variant="subtitle1SmallL" component="p" textAlign="start" color={'#313D53'}>
                    Παρακάτω μπορείς να διαχειριστείς και τους υπόλοιπους τύπους ενημερώσεων που λαμβάνεις από το
                    Pigogo.
                  </Typography>
                </Box>
              )}
              <Divider sx={{ borderColor: '#EAECEE' }} />
            </>
          )}
          <Box
            display="flex"
            gap={smDown ? 3 : 2}
            flexDirection="column"
            padding={smDown ? '24px 16px 24px 16px' : '32px 56px 32px 24px'}
          >
            <Typography variant="mediumM" component="h3" textAlign="start" color={'#1D2532'}>
              Ενημερώσεις στο email σου
            </Typography>

            {getRow(
              'Newsletter',
              'Θα σου στέλνουμε όταν έχουμε αυξήσεις ποσοστών επιστροφής, νέα καταστήματα ή αποκλειστικά promos.',
              'newsletter',
              newsletter,
            )}
            <Divider sx={{ borderColor: '#EAECEE' }} />
            {getRow(
              'Καταγραφή αγοράς',
              'Θα σου στέλνουμε όταν καταγράφεται μια αγορά σου στο Pigogo.',
              'purchase',
              purchase,
            )}
            <Divider sx={{ borderColor: '#EAECEE' }} />
            {getRow('Πληρωμή', 'Θα σου στέλνουμε όταν πραγματοποιείται μία πληρωμή σου.', 'payment', payment)}
            <Divider sx={{ borderColor: '#EAECEE' }} />

            <Box
              gap={3}
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={smDown ? 'column' : 'row'}
              alignItems={smDown ? 'start' : unsubscribe ? 'flex-end' : 'center'}
            >
              <Box gap={1} width={'100%'} display={'flex'} textAlign={'start'} flexDirection={'column'}>
                <Box>
                  <Typography variant={'subtitle1'} component="h4" color={'#313D53'}>
                    Αγαπημένα Καταστήματα
                  </Typography>
                </Box>
                <Box
                  gap={3}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={smDown ? 'column' : 'row'}
                  alignItems={'flex-start'}
                >
                  <Box flexShrink={1}>
                    <Typography variant={'subtitle1SmallR'} component="p" color={'#313D53'}>
                      Θα σου στέλνουμε όταν κάποιο από τα αγαπημένα σου καταστήματα έχει νέα προσφορά ή νέο κουπόνι
                      έκπτωσης.
                    </Typography>
                  </Box>
                  <Box display={'flex'} flexGrow={1} justifyContent={smDown ? 'flex-start' : 'flex-end'}>
                    <Link className="btnLink btnLink--underline btnLink--blue" to={'/favourites'}>
                      Επεξεργασία αγαπημένων
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ContactOptions;
