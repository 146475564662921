import React, { useEffect, useState } from 'react';
import { Box, Divider, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as InfoOutlined } from '../../../assets/svgs/info.svg';
import { RichTooltip, theme } from 'components';
import BankCard from './BankCard';
import VivaCard from './VivaCard';
import PaymentModal from './PaymentModal';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import moment from 'moment';
import { setSnackBar, setStateSnackBar } from '../../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import WarningMessage from '../Overview/WarningMessage';
import { UserData } from '../../../models/User';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../../../assets/jpg/pigogo-socialshare.jpg';

const StyleBox = styled(Box)(() => ({
  // height: '116px',
  [theme.breakpoints.down('md')]: { height: 'auto' },
  padding: '24px',
  justifyContent: 'space-between',
  border: '2px solid #EAECEE',
  borderRadius: '24px',
  boxSizing: 'border-box',
}));

const PaymentsOptions = () => {
  const dispatch = useDispatch();

  const { data: userData, isLoading: userDataLoading, isError: errorUserData } = useGetUserDataQuery();

  const [updateError, setUpdateError] = useState<boolean>(false);
  const [userDataFields, setUserDataFields] = useState<UserData | undefined>(undefined);

  useEffect(() => {
    if (!updateError) {
      setUserDataFields(userData);
    }
  }, [userData, updateError]);

  const checkPaymentDay = () => {
    if (userData && userData.next_payment) {
      const today = moment(new Date().getDate(), 'DD/MM/YYYY');
      const nextPayment = moment(userData.next_payment, 'DD/MM/YYYY');
      const isSame = moment(nextPayment).isSame(today, 'day');
      if (isSame) {
        return true;
      }
    }

    return false;
  };

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(checkPaymentDay());

  const MouseOver = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    if (errorUserData) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorUserData]);

  useEffect(() => {
    if (userData && !errorUserData) {
      setOpenModal(checkPaymentDay());
    }
  }, [userData?.payment_date]);

  const MouseOut = () => {
    setOpen(false);
  };

  const optionsBox = () => {
    return (
      <StyleBox sx={{ display: mdDown ? 'block' : 'flex' }}>
        <Box display="flex" flexDirection="column" gap={'4px'}>
          <Typography variant="large900" component="h3" color={'#1D2532'}>
            {userDataLoading ? <Skeleton sx={{ px: 3, width: '400px', maxWidth: '100%' }} /> : 'Στοιχεία πληρωμής'}
          </Typography>
          <Typography variant="subtitle1SmallL" component="p" color="#313D53">
            {userDataLoading ? (
              <Skeleton sx={{ px: 3, width: '400px', maxWidth: '100%' }} />
            ) : (
              'Το Pigogo πραγματοποιεί πληρωμές δύο φορές τον μήνα.'
            )}
          </Typography>
        </Box>

        <Box display={mdDown ? 'block' : 'flex'}>
          <Box py={mdDown ? 2 : 0} px={mdDown ? 0 : 2}>
            <Divider orientation={mdDown ? 'horizontal' : 'vertical'} />
          </Box>

          <Box width={mdDown ? '100%' : '110px'} gap={'4px'} display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle1SmallL" component="p" color="primary">
              {userDataLoading ? <Skeleton sx={{ px: 2 }} /> : 'Διαθέσιμο ποσό προς πληρωμή'}
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="largeB" component="p" color={'#1D2532'} fontWeight={700}>
                {userDataLoading ? <Skeleton sx={{ px: 2 }} /> : userData?.total_payment_due_cashback_amount}
              </Typography>
              <Box
                onMouseOver={MouseOver}
                onMouseOut={MouseOut}
                height={'24px'}
                width={'24px'}
                display={'inline-flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ cursor: 'pointer' }}
              >
                <RichTooltip
                  content={
                    <Typography variant={'caption'} component="p">
                      Το συνολικό ποσό που προκύπτει από όλες τις ΄Προς πληρωμή΄ αγορές σου
                    </Typography>
                  }
                  open={open}
                  placement="bottom"
                  color="dark"
                  onClose={() => setOpen(false)}
                >
                  <InfoOutlined />
                </RichTooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyleBox>
    );
  };

  return (
    <Box pb={mdDown ? 5 : 0} pt={mdDown ? 4 : undefined}>
      {location.pathname === '/user/payments-options' && (
        <Helmet>
          <title>Στοιχεία πληρωμής - Ο λογαριασμός μου | Pigogo</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="og:name" content="Pigogo" />
          <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
          <meta name="og:image" content={PigogoSocialShare} />
        </Helmet>
      )}
      {userData?.user_warning_message && (
        <Box display="flex" flexDirection="column" mt={mdDown ? -2 : undefined} mb={mdDown ? 2 : 2}>
          <WarningMessage />
        </Box>
      )}
      {optionsBox()}
      <Box display="flex" flexDirection="column" gap={2} mt={mdDown ? 2 : 4}>
        <BankCard userData={userDataFields} loading={userDataLoading} setUpdateError={setUpdateError} />
        <VivaCard userData={userDataFields} loading={userDataLoading} setUpdateError={setUpdateError} />
      </Box>

      <PaymentModal open={openModal} handleClose={() => setOpenModal(false)} />
    </Box>
  );
};

export default PaymentsOptions;
