import React, { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Skeleton,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import theme from '../theme';
import { Minus, Plus } from '../assets';

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  margin: 0,
  minHeight: 0,
}));

const StyledAccordion = styled(Accordion)(() => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    width: '24px',
    height: '24px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&.Mui-expanded': {
    outline: '2px solid #313D53',
  },
  '&.MuiAccordion-root': {
    margin: 0,
    minHeight: 0,
    borderRadius: '18px',
    padding: theme.spacing(4, 2, 0, 2),
  },
  '& .MuiButtonBase-root': {
    alignItems: 'flex-start',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  margin: 0,
}));

type NavAccordionProps<C extends React.ElementType> = {
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  subtitle?: string;
  accordionProps?: AccordionProps;
  summaryProps?: AccordionSummaryProps;
  detailsProps?: AccordionDetailsProps;
  titleProps?: TypographyProps<C, { component?: C }>;
  subtitleProps?: TypographyProps<C, { component?: C }>;
};

const PigogoContainAccordion: FC<NavAccordionProps<React.ElementType> & AccordionProps> = (
  props: NavAccordionProps<React.ElementType> & AccordionProps,
) => {
  const [isExpanded, setIsExpanded] = useState(props.expanded);

  useEffect(() => {
    setIsExpanded(props.expanded);
  }, [props.expanded]);

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    !props.disabled && setIsExpanded(expanded);
  };
  return (
    <StyledAccordion
      disableGutters
      elevation={0}
      expanded={isExpanded}
      onChange={handleChange}
      {...props.accordionProps}
    >
      <StyledAccordionSummary {...props.summaryProps} expandIcon={isExpanded ? <Minus /> : <Plus />}>
        <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Box>
            <Typography
              color={'#1D2532'}
              {...props.titleProps}
              variant={props.titleProps?.variant ? props.titleProps?.variant : 'body2'}
            >
              {props.loading ? <Skeleton width="200px" /> : props.title}
            </Typography>
          </Box>
          <Box pb={1}>
            <Typography
              color={'#313D53'}
              {...props.subtitleProps}
              variant={props.subtitleProps?.variant ? props.subtitleProps?.variant : 'caption'}
            >
              {props.loading ? <Skeleton width="250px" /> : props.subtitle}
            </Typography>
          </Box>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails {...props.detailsProps}>{props.children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default PigogoContainAccordion;
