import { Box, Typography } from '@mui/material';
import { PigogoAccordion, theme } from 'components';
import { FC, useEffect } from 'react';
import { useFaqQuery } from '../../redux/api/queries/faqQueries';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import useQuery from '../../hooks/useQuery';
import { useIonViewDidEnter } from '@ionic/react';

interface Props {
  categoryId: number;
  setAnchoredCategory?: (value: number) => void;
}

const FAQCategoryContent: FC<Props> = ({ categoryId, setAnchoredCategory }) => {
  const { data: innerContent, isLoading } = useFaqQuery(
    { categoryId: categoryId.toString() },
    { refetchOnMountOrArgChange: false },
  );
  const query = useQuery();

  useEffect(() => {
    const findId = query.get('id');
    if (findId && !isLoading) {
      if (innerContent?.data.filter((item) => item.id === Number(findId)).length) {
        setTimeout(() => {
          setAnchoredCategory && setAnchoredCategory(categoryId);
        }, 250);
        setTimeout(() => {
          const element =
            document.getElementById(findId)?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
              ?.parentElement;
          element?.scrollIntoView({
            behavior: 'smooth',
          });
        }, 1000);
      }
    }
  }, [innerContent]);

  const extractInnerContent = () => {
    if (!innerContent?.data.length && isLoading) {
      return [1, 1, 1, 1, 1].map((item, index) => (
        <Box key={index}>
          <PigogoAccordion key={index} disabled loading>
            -loading-
          </PigogoAccordion>
        </Box>
      ));
    } else
      return innerContent?.data.map((item, index) => (
        <PigogoAccordion
          key={index}
          expanded={query.get('id') == `${item.id}`}
          titleProps={{ variant: 'mediumM', component: 'h4', color: 'primary.dark' }}
          title={item.question}
        >
          <Typography variant="body2MediumL" component="div" color={'#313D53'} id={`${item.id}`}>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              className="markdown"
              components={{
                div: ({ node, ...props }) => (
                  <div
                    style={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: 300,
                      letterSpacing: 0,
                    }}
                    {...props}
                  />
                ),
                ol: ({ node, ...props }) => (
                  <ol style={{ paddingLeft: '18px', margin: 0 }} {...{ ...props, ordered: 'true' }} />
                ),
                ul: ({ node, ...props }) => (
                  <ul style={{ paddingLeft: '18px', margin: 0 }} {...{ ...props, ordered: 'true' }} />
                ),
                a: ({ node, ...props }) => (
                  <a
                    style={{
                      color: '#313D53',
                      textDecorationColor: '#313D53',
                      fontWeight: '400',
                    }}
                    {...props}
                  />
                ),
              }}
            >
              {item.answer}
            </ReactMarkdown>
          </Typography>
        </PigogoAccordion>
      ));
  };

  return <>{extractInnerContent()}</>;
};

export default FAQCategoryContent;
