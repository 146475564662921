import React, { FC, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useLazyGetBasicContentQuery } from '../redux/api/queries/basicContentQuery';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { theme } from 'components';
import Layout from '../components/Navigation/Layout';
import PigogoFooter from '../components/PigogoFooter';
import BasicContentBody from '../components/BasicContent/BasicContentBody';
import TopBannerImage from '../components/TopBannerImage';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import backArrow from '../assets/svgs/back_arrow.svg';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../assets/jpg/pigogo-socialshare.jpg';

const BasicContent: FC<RouteComponentProps> = () => {
  const location = useLocation();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [trigger, { data: basicContentData, isSuccess }] = useLazyGetBasicContentQuery();

  const markdown = basicContentData ? basicContentData.data.mainBody.replace(/\n/g, '<br>') : '';

  useEffect(() => {
    const split = location.pathname.split('/');
    if (split[2]) {
      trigger({ pageID: split[2] });
    }
  }, [location]);

  return (
    <IonPage>
      {location.pathname.includes('/page') && isSuccess && (
        <Helmet>
          <title>{basicContentData?.data.title} | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="og:name" content="Pigogo" />
          <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
          <meta name="og:image" content={PigogoSocialShare} />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      {downMd && (
        <IonHeader class="ion-no-border">
          <IonToolbar
            style={{
              '--background': '#FFFFFF',
              '--border-width': 0,
              paddingTop: '0 + --ion-safe-area-top',
            }}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
              <IonButtons slot="start">
                <IonBackButton
                  className="backBtn"
                  text=""
                  style={{
                    '--color': '#313D53',
                    '--padding-start': 0,
                    '--padding-end': 0,
                    '--icon-font-size': '24px',
                    '--border-radius': 0,
                    '--min-height': '24px',
                    '--min-width': '24px',
                    width: '24px',
                    height: '24px',
                    minWidth: '24px',
                    maxHeight: '24px',
                    display: 'inline-flex',
                  }}
                  icon={backArrow}
                  defaultHref={'/'}
                />
              </IonButtons>
              <Typography component="h2" variant="body2" color={'#313D53'}>
                {basicContentData ? basicContentData.data.menuBarName : 'n/a'}
              </Typography>
              <Box minWidth={'24px'}></Box>
            </Box>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent>
        <Layout>
          <>
            <TopBannerImage
              image={basicContentData?.data.image}
              title={basicContentData ? basicContentData.data.title : ''}
              shape={true}
            >
              <div dangerouslySetInnerHTML={{ __html: basicContentData?.data.subtitle }} />
            </TopBannerImage>
            <BasicContentBody>
              <style>
                {`
                  p {
                    color: #313D53;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.5;
                  }
                  p strong,p b {
                    font-size: 18px;
                    font-weight: 500;
                    color: #1D2532;
                  }
                  p a:link,
                  p a:visited {
                    color: #1D2532;
                    transition: color .2s ease;
                  }
                  p a:hover {
                    color: #E9688A;
                  }
                `}
              </style>
              <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} />
            </BasicContentBody>
            {isDesktop && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default BasicContent;
