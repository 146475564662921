import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import TermsAndConditions from '../components/User/TermsAndConditions';
import { theme } from 'components';
import Layout from '../components/Navigation/Layout';
import TopBanner from '../components/User/TermsAndConditions/TopBanner';
import PigogoFooter from '../components/PigogoFooter';
import React, { useRef } from 'react';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import CustomHeader from '../components/CustomHeader';
import PigogoSocialShare from '../assets/jpg/pigogo-socialshare.jpg';

const TermsAndConditionsPage = () => {
  const desktop = isPlatform('desktop');

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  const chooseLayout = () => {
    if (smDown) {
      return (
        <Box pt={'56px'} pb={'64px'}>
          <TermsAndConditions />
        </Box>
      );
    } else if (mdDown) {
      return (
        <>
          <TopBanner title={'Όροι χρήσης'} />
          <Box pt={'104px'} pb={'162px'}>
            <TermsAndConditions />
          </Box>
        </>
      );
    } else {
      return (
        <>
          <TopBanner title={'Όροι χρήσης'} />
          <Box pt={'104px'} pb={'162px'}>
            <TermsAndConditions />
          </Box>
          <PigogoFooter />
        </>
      );
    }
  };

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            <CustomHeader />
            {mdDown && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Όροι χρήσης
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
            {location.pathname === '/terms' && (
              <Helmet>
                <title>Όροι χρήσης | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
                <meta name="og:name" content="Pigogo" />
                <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
                <meta name="og:image" content={PigogoSocialShare} />
              </Helmet>
            )}
          </Box>
          {chooseLayout()}
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default TermsAndConditionsPage;
