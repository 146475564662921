import React, { FC, useEffect, useRef, useState } from 'react';
import { IonContent, IonPage, isPlatform, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import AlertSection from '../components/Home/AlertSection';
import BlogSection from '../components/Home/BlogSection';
import HeroSectionPost from '../components/Home/HeroSectionPost';
import NewShopsSection from '../components/Home/NewShopsSection';
import OffersSection from '../components/Home/OffersSection';
import RecentsSection from '../components/Home/RecentsSection';
import PigogoFooter from '../components/PigogoFooter';
import { useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import Layout from '../components/Navigation/Layout';
import CustomHeaderHome from '../components/CustomHeaderHome';
import WalkthroughModal from '../components/Navigation/Modals/WalkthroughModal';
import StartStepsAlert from '../components/Home/StartStepsAlert';
import { getRecentlyViewed, reviewViewedRecentlyStorage, updateShopData } from '../utils/viewedRecently';
import { useGetUserDataQuery } from '../redux/api/queries/userQueries';
import { setSnackBar } from '../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { Shop } from '../models/Shop';
import { useAppSelector } from '../redux/hooks';
import { setFirstLogin } from '../redux/slices/authenticationSlice';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useLazyShopsQuery } from '../redux/api/queries/shopQuery';
import PigogoSocialShare from '../assets/jpg/pigogo-socialshare.jpg';

interface Props {
  handleRedir?: () => void;
  passwordChanged?: boolean;
}

const Home: FC<Props> = ({ handleRedir, passwordChanged = false }) => {
  const isFirst = useAppSelector((state) => state.authentication.data.firstLogin);

  const dispatch = useDispatch();

  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { data: userData } = useGetUserDataQuery();
  const [trigger, { data: shopsData, isFetching: isFetchingRecentlyViewedShops }] = useLazyShopsQuery();

  const [startStep, setStartStep] = useState(false);
  const [walkthrough, setWalkthrough] = useState<boolean>(false);
  const [viewRecently, setViewRecently] = useState<{ shop: Shop; createdAt: string }[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(true);

  const [seenSnackBar, setSeenSnackBar] = useState<boolean>(false);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  function handleScrollEnd(e: any) {
    history.location.pathname === '/home' && history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  useEffect(() => {
    if (userData && userData.id) {
      reviewViewedRecentlyStorage(userData.id);
      const recent: { shop: Shop; createdAt: string }[] = getRecentlyViewed(userData.id);
      const shopsIds = recent
        .map(function (elem: any) {
          return elem.shop.id;
        })
        .join(',');

      const getUpdatedShopsData = async function () {
        return await trigger({
          id: shopsIds,
        });
      };

      getUpdatedShopsData().then(async function (value) {
        const updatedRecent = await updateShopData(userData.id, value);
        setViewRecently((updatedRecent || recent).slice(0, 5));
      });
    }
  }, [userData]);

  //This approach 'triggers' the Password-Change Snackbar despite the props actually updating correctly.
  /*
  useIonViewDidEnter(() => {
    contentRef.current && contentRef.current.scrollToTop();

    // Paradoxically, when this console.log is reached, the old 'true' value is printed out.
    console.log(passwordChanged);
    if (passwordChanged === true && handleRedir !== undefined) {
      dispatch(setSnackBar({ value: true, msg: 'Ο νέος σου κωδικός καταχωρήθηκε με επιτυχία!', severity: 'success' }));
      handleRedir();
    }
  });
  */

  useIonViewDidEnter(() => {
    setIsFocused(true);

    if (localStorage.getItem('tab-changed') === '1') {
      contentRef.current && contentRef.current.scrollToTop();
    }
    localStorage.removeItem('tab-changed');

    if (isFirst) {
      setTimeout(() => {
        tablet && setStartStep(true);
        tablet && dispatch(setFirstLogin({ isFirst: false }));
      }, 7000);
    }
  });

  useEffect(() => {
    if (isFirst) {
      if (!seenSnackBar) {
        const params = { value: true, msg: 'Ο λογαριασμός σου δημιουργήθηκε με επιτυχία!', severity: 'success' };
        dispatch(setSnackBar(params));
        setSeenSnackBar(false);
      }
    }
  }, [seenSnackBar, isFirst]);

  useIonViewWillLeave(() => {
    setIsFocused(false);
  });

  // This approach remedies the issue of the Password-Change Snackbar persisting among accesses of the post-login homepage.
  useEffect(() => {
    if (passwordChanged === true && handleRedir !== undefined) {
      dispatch(setSnackBar({ value: true, msg: 'Ο νέος σου κωδικός καταχωρήθηκε με επιτυχία!', severity: 'success' }));
    }
    if (handleRedir !== undefined) {
      handleRedir();
    }
  }, [passwordChanged]);

  const handleLetsStart = () => {
    setWalkthrough(true);
    setStartStep(false);
  };

  return (
    <IonPage>
      {location.pathname === '/' && (
        <Helmet>
          <title>{'Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου'}</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="og:name" content="Pigogo" />
          <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
          <meta name="og:image" content={PigogoSocialShare} />
        </Helmet>
      )}
      <CustomHeaderHome />
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout permitShowLogo={true}>
          <>
            <HeroSectionPost />
            <OffersSection />
            <NewShopsSection />
            {!tablet && <AlertSection />}
            {viewRecently.length > 0 && <RecentsSection recentShops={viewRecently} />}
            <BlogSection />
            {!tablet && <PigogoFooter />}
          </>
        </Layout>
        {(mobile || tablet) && (
          <StartStepsAlert
            open={startStep}
            setOpen={setStartStep}
            title="Δες πόσο εύκολα μπορείς να κερδίζεις επιστροφή χρημάτων."
            actionButton={<PigogoButton fullWidth text="Ας ξεκινήσουμε" variation="white" onClick={handleLetsStart} />}
          />
        )}
        {(mobile || tablet) && (
          <WalkthroughModal
            open={walkthrough}
            handleClose={() => {
              setWalkthrough(false);
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
