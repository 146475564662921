import { Box, Typography, useMediaQuery } from '@mui/material';
import { IonContent, IonHeader, IonPage, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import Pigogo from '../assets/svgs/logo/light/pigogo_m.svg';
import PigogoSad from '../assets/svgs/illustrations/pigogoSad.svg';
import ContactOptions from '../components/User/ContactOptions';
import CustomContainer from '../components/CustomContainer';
import {
  usePatchUnsubscribeMutation,
  usePatchUserNotificationsUnsubscribeMutation,
} from '../redux/api/mutations/unsubscribeMutation';
import { useDecryptQuery, useLazyGetUserNotificationsQuery } from '../redux/api/queries/unsubscribeQueries';
import { theme } from 'components';
import useQuery from '../hooks/useQuery';
import { useEffect, useState } from 'react';
import ContactOptionsUnsubscribe from '../components/User/ContactOptionsUnsubscribe';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../assets/jpg/pigogo-socialshare.jpg';

const UnsubscribePage = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  interface UserNotifications {
    notification_newsletter: boolean;
    notification_new_payment: boolean;
    notification_new_purchase: boolean;
  }

  const defaultNotifications = {
    notification_newsletter: false,
    notification_new_payment: false,
    notification_new_purchase: false,
  };

  const params = useQuery();
  const [shop, setShop] = useState<string | null>(null);

  const [notifs, setNotifs] = useState<UserNotifications>(defaultNotifications);

  const { data: shopUnsubscribe, isLoading: shopLoading } = useDecryptQuery({ name: params.get('name') || '' });

  const [patchUnsubscribe, { isLoading: unsubscribeLoading, isSuccess: unsubscribeSuccess }] =
    usePatchUnsubscribeMutation();

  const [triggerGetNotifications, { data: userNotifications, isError: getUserNotificationsError }] =
    useLazyGetUserNotificationsQuery();

  useEffect(() => {
    if (!shopLoading && shopUnsubscribe?.shopName) {
      setShop(shopUnsubscribe?.shopName);
    }
  }, [shopUnsubscribe, shopLoading]);

  useIonViewDidEnter(async () => {
    await patchUnsubscribe({ email: params.get('email') || '', name: params.get('name') || '' });
    const data = await triggerGetNotifications({ email: params.get('email') || '' });

    setNotifs(data.data || defaultNotifications);
  });

  return !shopLoading ? (
    <IonPage>
      <IonHeader class="ion-no-border" style={{ borderBottom: !mobile ? '1px solid #EAECEE' : 'none' }}>
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            '--border-width': 0,
            paddingTop: '0 + --ion-safe-area-top',
            textAlign: 'center',
          }}
        >
          <Link to={'/'}>
            <img alt={'pigogoApp'} src={Pigogo} style={{ paddingTop: '24px', paddingBottom: !mobile ? '24px' : '' }} />
          </Link>
        </IonToolbar>
      </IonHeader>
      {location.pathname === '/unsubscribe' && (
        <Helmet>
          <title>Ρυθμίσεις επικοινωνίας | Pigogo - Επιστροφή, προσφορές, εκπτώσεις</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="og:name" content="Pigogo" />
          <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
          <meta name="og:image" content={PigogoSocialShare} />
        </Helmet>
      )}
      <IonContent>
        <CustomContainer>
          <Box
            gap={4}
            display="flex"
            px={mdDown ? 3 : 0}
            pt={mobile ? 2 : 10}
            pb={mobile ? 2 : 14}
            flexDirection="column"
            alignItems={'center'}
          >
            <Box pt={mobile ? 3 : ''}>
              <img alt={'pigogoApp'} src={PigogoSad} />
            </Box>
            <Box bgcolor="#FEF6F8" borderRadius={'24px'} p={mobile ? 4 : 6} maxWidth={'606px'} textAlign="center">
              <Typography fontSize={'20px'} lineHeight={'24px'} fontWeight={700} color={'#1D2532'}>
                Το email σου έχει αφαιρεθεί από τη λίστα επικοινωνίας
                {!shopLoading && shop ? ` για το κατάστημα ${shop}` : ' του Newsletter μας'}
              </Typography>
            </Box>
          </Box>
          <ContactOptionsUnsubscribe userNotifications={notifs} setUserNotifications={setNotifs} />
        </CustomContainer>
      </IonContent>
    </IonPage>
  ) : (
    <IonPage></IonPage>
  );
};

export default UnsubscribePage;
