import React from 'react';
import { Button, Snackbar, styled, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ErrorRoundedIcon from '../../../../apps/pigogo-app/src/assets/svgs/errorSnackbar.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoWhite } from '../assets';

const AlertStyled = styled(MuiAlert)(() => ({
  color: 'var(--text)',
  borderRadius: '8px',
  fontSize: '16px',
  fontWeight: '500',
  background: 'var(--background)',
  alignItems: 'center',
}));

const successVars = {
  '--background': '#3CA377',
  '--text': '#ffffff',
} as React.CSSProperties;

const errorVars = {
  '--background': '#BC415A',
  '--text': '#FFFFFF',
} as React.CSSProperties;

const infoVars = {
  '--background': '#ECEFF4',
  '--text': '#313D53',
} as React.CSSProperties;

const successLightVars = {
  '--background': '#CAF3E1',
  '--text': '#2C374B',
} as React.CSSProperties;

const errorLightVars = {
  '--background': '#FDCCD6',
  '--text': '#2C374B',
} as React.CSSProperties;

const infoLightVars = {
  '--background': '#EAECEE',
  '--text': '#2C374B',
} as React.CSSProperties;

const warningLightVars = {
  '--background': '#FEE2CF',
  '--text': '#2C374B',
} as React.CSSProperties;

const successDarkVars = {
  '--background': '#3CA377',
  '--text': '#FFFFFF',
} as React.CSSProperties;

const errorDarkVars = {
  '--background': '#BC415A',
  '--text': '#FFFFFF',
} as React.CSSProperties;

const infoDarkVars = {
  '--background': '#2AA2B8',
  '--text': '#FFFFFF',
} as React.CSSProperties;

const warningDarkVars = {
  '--background': '#C05E48',
  '--text': '#FFFFFF',
} as React.CSSProperties;

const infoBlueVars = {
  '--background': '#313D53',
  '--text': '#FFFFFF',
} as React.CSSProperties;

interface Props {
  icon?: boolean;
  isOpen: boolean;
  message: string;
  closeButton?: boolean;
  severity?:
    | 'infoBlue'
    | 'success'
    | 'error'
    | 'info'
    | 'successLight'
    | 'errorLight'
    | 'infoLight'
    | 'warningLight'
    | 'successDark'
    | 'errorDark'
    | 'infoDark'
    | 'warningDark';
  vertical?: 'bottom' | 'top';
  horizontal?: 'center' | 'left' | 'right';
  handleUndo?: () => void;
  setSnackbar?: (isOpen: boolean) => void;
}

const PigogoSnackbar: React.FC<Props> = ({
  isOpen,
  message,
  severity,
  icon = true,
  closeButton,
  handleUndo,
  horizontal = 'center',
  vertical = 'bottom',
  setSnackbar,
}) => {
  const handleClose = () => {
    if (setSnackbar) {
      setSnackbar(false);
    }
  };

  const getVar = () => {
    switch (severity) {
      case 'success':
        return successVars;
      case 'error':
        return errorVars;
      case 'info':
        return infoVars;
      case 'successLight':
        return successLightVars;
      case 'errorLight':
        return errorLightVars;
      case 'infoLight':
        return infoLightVars;
      case 'warningLight':
        return warningLightVars;
      case 'successDark':
        return successDarkVars;
      case 'errorDark':
        return errorDarkVars;
      case 'infoDark':
        return infoDarkVars;
      case 'warningDark':
        return warningDarkVars;
      case 'infoBlue':
        return infoBlueVars;
    }
  };

  const extractIcon = () => {
    if (severity === 'success' || severity === 'successLight') {
      return <DoneRoundedIcon sx={{ color: '#ffffff' }} />;
    } else if (severity === 'error') {
      return <ErrorRoundedIcon />;
    } else if (severity === 'errorLight') {
      return <ErrorRoundedIcon />;
    } else if (severity === 'info' || severity === 'infoLight') {
      return <InfoOutlinedIcon color="primary" />;
    } else if (severity === 'infoBlue') {
      return <InfoWhite />;
    }
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: horizontal, vertical: vertical }}
    >
      <AlertStyled
        style={getVar()}
        onClose={closeButton ? handleClose : undefined}
        icon={icon ? extractIcon() : false}
        elevation={6}
      >
        {message}
        {handleUndo && (
          <>
            <br />
            <Button onClick={handleUndo}>
              <Typography variant={'subtitle1SmallM'} color="white" sx={{ textDecoration: 'underline', ml: '-10px' }}>
                Αναίρεση
              </Typography>
            </Button>
          </>
        )}
      </AlertStyled>
    </Snackbar>
  );
};

export default PigogoSnackbar;
