import React, { FC, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { PigogoButton, PigogoFloatingAlert, theme } from 'components';
import HeroSection from '../components/Home/HeroSection';
import WinSection from '../components/Home/WinSection';
import { IonContent, IonPage, isPlatform, useIonRouter, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import ShopsSection from '../components/Home/ShopsSection';
import AlertSection from '../components/Home/AlertSection';
import HotOffersSection from '../components/Home/HotOffersSection';
import MoneyReturnSection from '../components/Home/MoneyReturnSection';
import MemberSection from '../components/Home/MemberSection';
import DownloadSection from '../components/Home/DownloadSection';
import QuestionSection from '../components/Home/QuestionSection';
import PigogoFooter from '../components/PigogoFooter';
import PigogoLoginRegister from '../components/PigogoLoginRegister';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import Layout from '../components/Navigation/Layout';
import CustomHeaderHome from '../components/CustomHeaderHome';
import VideoModal from '../components/Navigation/Modals/VideoModal';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import useQuery from '../hooks/useQuery';
import { useAppSelector } from '../redux/hooks';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../assets/jpg/pigogo-socialshare.jpg';

interface Props {
  openLoginModal?: boolean;
  openRegisterModal?: boolean;
  showCookies?: boolean;
  loggedInLoading?: boolean;
  initializeCookies?: (val: boolean) => void;
}

const HomePreLogin: FC<Props> = ({
  openLoginModal,
  openRegisterModal,
  showCookies = true,
  loggedInLoading,
  initializeCookies,
}) => {
  const desktop = isPlatform('desktop');
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const tabletUp = useMediaQuery(theme.breakpoints.up('md'));

  const query = useQuery();

  const router = useIonRouter();
  const path = router.routeInfo.pathname;

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [cookies, setCookies] = useState<boolean>(showCookies);
  const cookiesPrevious = useRef<boolean>(true);

  const [video, setVideo] = useState(false);
  const [loginModal, setLoginModal] = useState<boolean>(openLoginModal ? openLoginModal : false);
  const [registerModal, setRegisterModal] = useState<boolean>(openRegisterModal ? openRegisterModal : false);
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [successForgotModal, setSuccessForgotModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  useIonViewDidEnter(() => {
    history.action !== 'PUSH' &&
      contentRef.current &&
      contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);

    if (query.get('login')) {
      setLoginModal(true);
      setRegisterModal(false);
    }

    if (query.get('signup')) {
      setLoginModal(false);
      setRegisterModal(true);
    }
  });

  useEffect(() => {
    if (query.get('login') === '') {
      setLoginModal(true);
      setRegisterModal(false);
    }

    if (query.get('signup') === '') {
      setLoginModal(false);
      setRegisterModal(true);
    }
  }, [query]);

  return (
    <IonPage>
      <CustomHeaderHome />
      {location.pathname === '/' && !accessToken && !sessionLoggedIn && (
        <Helmet>
          <title>{`Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου`}</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="og:name" content="Pigogo" />
          <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
          <meta name="og:image" content={PigogoSocialShare} />
        </Helmet>
      )}
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <>
            <HeroSection setVideo={setVideo} onclickSignIn={() => setLoginModal(true)} />
            <WinSection />
            <ShopsSection />
            {!tablet && <AlertSection />}
            {!tablet && (
              <HotOffersSection
                message={setMessage}
                setLogin={() => {
                  return;
                }}
              />
            )}
            {tabletUp && <MoneyReturnSection />}
            {tabletUp && <MemberSection />}
            <DownloadSection
              readMore
              title={'Pigogo mobile app'}
              subtitle={
                <span>
                  Κατέβασε την εφαρμογή του Pigogo στο κινητό σου. <br /> Διαθέσιμη σε
                  <strong> Google Play </strong> και <strong>App Store</strong>
                </span>
              }
            />
            {tabletUp && <QuestionSection hasExtraPadding />}
            {tablet && (
              <HotOffersSection
                message={setMessage}
                setLogin={() => {
                  return;
                }}
              />
            )}
            {tablet && <MoneyReturnSection />}
            {tablet && <MemberSection />}
            {tablet && <QuestionSection hasExtraPadding />}
            <PigogoLoginRegister openLogin={() => setLoginModal(true)} openRegister={() => setRegisterModal(true)} />
            {tabletUp && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
      <ForgotModal isOpen={forgotModal} setOpen={setForgotModal} success={setSuccessForgotModal} />
      <SuccessPasswordModal
        title={'Ευχαριστούμε πολύ!'}
        subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
        open={successForgotModal}
        handleClose={setSuccessForgotModal}
      />
      <RegisterModal
        isOpen={registerModal}
        setOpen={setRegisterModal}
        setLoginModal={setLoginModal}
        userDismiss={() => {
          setLoginModal(false);
          setRegisterModal(false);
        }}
        successCallback={() => {
          setLoginModal(false);

          if (openRegisterModal) {
            setTimeout(() => {
              router.push('/');
            }, 500);
          }
        }}
      />
      <LoginModal
        message={message}
        isOpen={loginModal}
        setLoginModal={(value: boolean) => {
          setLoginModal(value);
        }}
        dismiss={() => {
          setLoginModal(false);
        }}
        userDismiss={() => {
          setLoginModal(false);
          router.push('/');
        }}
        openRegisterModal={() => {
          setRegisterModal(true);
          if (path.includes('loginModal=true')) {
            setLoginModal(false);
          }
        }}
        openForgotModal={() => setForgotModal(true)}
      />
      {/*
        <PigogoFloatingAlert
          open={cookies}
          setOpen={setCookies}
          title="Για να σου προσφέρουμε την καλύτερη εμπειρία, χρησιμοποιούμε cookies."
          learnMore={() => console.log('learn more')}
          learnMoreText="Μάθε περισσότερα"
          color="light"
          actionButton={
            <PigogoButton
              text="Εντάξει"
              variation="primary"
              onClick={() => {
                setCookies(false);
                cookiesPrevious.current = false;
                initializeCookies && initializeCookies(false);
              }}
              sx={{ backgroundColor: 'transparent', color: '#313D53', border: '1px solid #313D53', width: '256px' }}
            />
          }
        />
        )*/}
      <VideoModal open={video} handleClose={() => setVideo(false)} />
    </IonPage>
  );
};

export default HomePreLogin;
