import { pigogoApi } from '../Api';
import { Favourite } from '../../../models/Favourite';
import { FavouriteParams } from '../types/FavouriteParams';
import { GenericRequest } from '../../../models/GenericRequest';
import { GenericResponse } from '../../../models/GenericResponse';
import { extractData } from '../../../utils/extractData';

const favouritesApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    favourites: builder.query<GenericRequest<Favourite[]>, FavouriteParams>({
      query: ({ nextCursor, limit, order, sort }) => ({
        method: 'GET',
        url: '/users/self/favourites',
        params: { cursor: nextCursor, limit, order, sort },
      }),
      providesTags: ['favourites'],
      transformResponse(response: GenericResponse<Favourite[]> | Favourite[], meta) {
        const { responseData, nextCursor, totalCount } = extractData<Favourite[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
    }),
    favouritesListing: builder.query<any, FavouriteParams>({
      query: () => ({
        method: 'GET',
        url: '/users/self/favourites',
      }),
      providesTags: ['favourites'],
      transformResponse(response: GenericResponse<Favourite[]> | Favourite[], meta) {
        const { responseData, nextCursor, totalCount } = extractData<Favourite[]>(response);

        let transformedResponse: number[] = [];
        responseData.forEach((element) => {
          transformedResponse = {
            ...transformedResponse,
            [`${element.shop.id}`]: element.id,
          };
        });

        return {
          data: transformedResponse,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
    }),
  }),
});

export const { useFavouritesQuery, useLazyFavouritesQuery, useLazyFavouritesListingQuery } = favouritesApi;
