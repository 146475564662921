import React, { FC, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import NoResults from './NoResults';
import CustomContainer from '../CustomContainer';
import { FAQCategory } from '../../models/FAQCategory';
import { GenericRequest } from '../../models/GenericRequest';
import { FAQData } from '../../models/FAQData';
import FAQCategoryContent from './FaqCategoryContent';
import { PigogoAccordion, PigogoContainAccordion, theme } from 'components';

interface Props {
  search: string;
  searchDataLoading?: boolean;
  allCategories: FAQCategory[];
  allCategoriesLoading: boolean;
  searchData?: GenericRequest<FAQData[]>;
}

const AnswerSection: FC<Props> = ({ search, allCategories, allCategoriesLoading, searchData, searchDataLoading }) => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchoredCategory, setAnchoredCategory] = useState<number | null>(null);

  const renderLoading = () => {
    return [1, 1, 1, 1].map((item, index) => (
      <Box key={index}>
        <PigogoContainAccordion
          loading
          disabled
          titleProps={{ variant: 'body1', component: 'p' }}
          subtitleProps={{ variant: 'body2MediumL', component: 'p' }}
        >
          -loading-
        </PigogoContainAccordion>
      </Box>
    ));
  };

  const renderAllCategories = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {allCategories.map((item, index) => (
          <Box key={index}>
            <PigogoContainAccordion
              title={item.name}
              subtitle={item.description}
              titleProps={{ variant: 'body1', component: 'h3' }}
              subtitleProps={{ variant: 'body2MediumL', component: 'p' }}
              expanded={anchoredCategory === item.id}
            >
              <FAQCategoryContent categoryId={item.id} setAnchoredCategory={setAnchoredCategory} />
            </PigogoContainAccordion>
          </Box>
        ))}
      </Box>
    );
  };

  const renderSearchResults = () => {
    if (searchData?.data?.length === 0) return <NoResults />;
    return (
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box>
          <Typography variant="captionM" component="p" role="alert" color="#838B98">
            {searchData && searchData.totalCount > 1 ? 'Βρέθηκαν' : 'Βρέθηκε'} {searchData ? searchData.totalCount : 0}{' '}
            {searchData && searchData.totalCount > 1 ? 'αποτελέσματα' : 'αποτέλεσμα'}
          </Typography>
        </Box>
        <Box paddingLeft={4}>
          {searchData?.data.map((item, index) => (
            <PigogoAccordion
              titleProps={{ variant: 'mediumM', component: 'h3' }}
              id={`faq-${item.id}`}
              key={index}
              title={item.question}
              noBorder={index === 0}
            >
              <Typography variant="body2MediumL">{item.answer}</Typography>
            </PigogoAccordion>
          ))}
        </Box>
      </Box>
    );
  };

  const renderSearchLoading = () => {
    return [1, 1, 1, 1, 1].map((item, index) => (
      <Box>
        <PigogoAccordion key={index} disabled loading>
          -loading-
        </PigogoAccordion>
      </Box>
    ));
  };

  const renderNoResults = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <NoResults />
      </Box>
    );
  };

  const handleContent = () => {
    if (search === '' && !allCategoriesLoading) {
      return renderAllCategories();
    } else if (search === '' && allCategoriesLoading) {
      return renderLoading();
    } else if (search !== '' && !searchDataLoading && searchData?.data.length) {
      return renderSearchResults();
    } else if (search !== '' && searchDataLoading) {
      return renderSearchLoading();
    } else if (search !== '' && !searchDataLoading && !searchData?.data.length) {
      return renderNoResults();
    }
  };

  return (
    <CustomContainer pt={7} pb={mobile ? 4 : 6}>
      <Box display={'flex'} flexDirection={'column'} justifyContent="center">
        {handleContent()}
      </Box>
    </CustomContainer>
  );
};

export default AnswerSection;
