import React from 'react';
import { Box, Button, Icon, List, Paper, Skeleton, styled, Typography } from '@mui/material';
import { ReactComponent as Bag } from '../../assets/svgs/bagPink.svg';
import { ReactComponent as Euro } from '../../assets/svgs/euroPink.svg';
import { ReactComponent as CreditCard } from '../../assets/svgs/creditCardPink.svg';
import HeroCardItem from './HeroCardItem';
import { useIonRouter } from '@ionic/react';
import { useGetUserDataQuery, useGetUserPurchasesQuery } from '../../redux/api/queries/userQueries';
import ExtractSaver from '../ExtractSaver';
import { ReactComponent as ChevronRight } from '../../assets/svgs/chevronRight24.svg';
import { Link } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: '16px',
  padding: theme.spacing(4),
  background: theme.palette.error.contrastText,
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    width: '416px',
  },
}));

const HeroCard = () => {
  const router = useIonRouter();

  const { data: userData, isLoading: userDataLoading } = useGetUserDataQuery();
  const { data: purchases, isLoading: purchasesLoading } = useGetUserPurchasesQuery({ limit: 4 });

  const getSaverIcon = () => {
    if (userDataLoading) {
      return (
        <Typography variant="subtitle1">
          <Skeleton sx={{ width: '71px' }} />
        </Typography>
      );
    }

    if (userData && userData?.gamification_level_id !== undefined && userData?.gamification_level_id !== 0) {
      return <ExtractSaver saver={userData?.gamification_level_id} />;
    } else {
      return (
        <Typography color="#838B98" variant="subtitle1SmallR" component="p">
          Κάνε την πρώτη σου αγορά και γίνε{' '}
          <Typography color="#FC9E5F" display="inline" variant="subtitle1">
            Saver
          </Typography>
        </Typography>
      );
    }
  };

  const userHasSetPaymentMethod = () => {
    if (userData?.payment_method_id == 1 && userData?.payment_bank_iban) {
      return true;
    } else if (userData?.payment_method_id == 3 && userData?.viva_wallet_phone_number) {
      return true;
    } else {
      return false;
    }
  };

  /*prettier-ignore*/
  return (
    <StyledPaper>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="body2" color={'#313D53'}>
          {userDataLoading && <Skeleton />}
          {!userDataLoading && userData?.first_name
            ? `${userData?.full_name}`
            : userData?.email}
        </Typography>
        {getSaverIcon()}
        <Link className={"accountLink accountLink--mTop" + (userDataLoading ? 'accountLink--disabled' : "")} to={'/user/overview'}>
          {userDataLoading ? <Skeleton sx={{ width: '144px' }} /> : 'Ο λογαριασμός μου'}
          <ChevronRight />
        </Link>
      </Box>
      <Box pt={'42px'}>
        <List disablePadding sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
          <HeroCardItem
            title={
              purchasesLoading ? (
                <Skeleton sx={{ width: '56px' }} />
              ) : ((purchases?.totalCount) ? ((purchases?.totalCount>1) ? `${purchases?.totalCount} αγορές` : '1 αγορά') : '0 αγορές'
              )
            }
            subtitle={
              purchasesLoading ? (
                <Skeleton sx={{ width: '112px' }} />
              ) : purchases?.totalCount === 0 ? (
                <Link className="btnLink btnLink--underline btnLink--small" to={'/how-it-works'}>
                  Δες πώς να ξεκινήσεις τις αγορές σου
                </Link>
              ) : (
                <Link className="btnLink btnLink--underline btnLink--small" to={'/user/purchases'}>
                  Δες τις αγορές σου
                </Link>
              )
            }
            icon={userDataLoading ? <Skeleton variant="circular" sx={{ height: '48px', width: '48px' }} /> : <Bag />}
          />
          <HeroCardItem
            title={
              userDataLoading ? (
                <Skeleton sx={{ width: '129px' }} />
              ) : (
                `${userData?.total_tracked_cashback_amount} επιστροφή` ?? '0,00 € επιστροφή'
              )
            }
            subtitle={
              userDataLoading ? (
                <Skeleton sx={{ width: '129px' }} />
              ) : !purchases?.totalCount ? (
                <Link className="btnLink btnLink--underline btnLink--small" to={'/stores'}>
                  Ξεκίνα να κερδίζεις επιστροφή χρημάτων
                </Link>
              ) : undefined
            }
            icon={userDataLoading ? <Skeleton variant="circular" sx={{ height: '48px', width: '48px' }} /> : <Euro />}
          />
          <HeroCardItem
            title={
              userDataLoading ? (
                <Skeleton sx={{ width: '129px' }} />
              ) : (
                `${userData?.total_payment_due_cashback_amount} προς πληρωμή` ?? '0€ προς πληρωμή'
              )
            }
            subtitle={
              userDataLoading ? (
                <Skeleton sx={{ width: '158px' }} />
              ) : !userHasSetPaymentMethod() ? (
                <Link className="btnLink btnLink--underline btnLink--small" to={'/user/payments-options'}>
                  Διάλεξε πώς θέλεις να πληρώνεσαι
                </Link>
              ) : (userData?.is_under_payment_threshold) ? (
                <Link className="btnLink btnLink--underline btnLink--small" to={'/user/payments-options'}>
                  Κάτω από το ελάχιστο ποσό πληρωμής σου
                </Link>
              ) : <Typography component="span" variant="subtitle1SmallR" color="#838B98">
                    Επόμενη πληρωμή: {userData?.next_payment ? userData?.next_payment : 'Δεν υπάρχει'}
                  </Typography>
            }
            icon={
              userDataLoading ? <Skeleton variant="circular" sx={{ height: '48px', width: '48px' }} /> : <CreditCard />
            }
          />
        </List>
      </Box>
    </StyledPaper>
  );
};

export default HeroCard;
