import React, { useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import AnswerSection from '../components/FAQ/AnswerSection';
import SupportSection from '../components/FAQ/SupportSection';
import TopBanner from '../components/FAQ/TopBanner';
import Layout from '../components/Navigation/Layout';
import { useFaqQuery, useGetAllFaqCategoriesQuery, useLazyFaqQuery } from '../redux/api/queries/faqQueries';
import useDebounce from '../hooks/useDebounce';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import PigogoFooter from '../components/PigogoFooter';
import backArrow from '../assets/svgs/back_arrow.svg';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import CustomHeader from '../components/CustomHeader';
import { isIos } from '../utils/device';
import useQuery from '../hooks/useQuery';

const FAQPage = () => {
  const { data: innerContent1, isLoading: isLoading1 } = useFaqQuery({ categoryId: '1' });
  const { data: innerContent2, isLoading: isLoading2 } = useFaqQuery({ categoryId: '2' });
  const { data: innerContent3, isLoading: isLoading3 } = useFaqQuery({ categoryId: '3' });
  const { data: innerContent4, isLoading: isLoading4 } = useFaqQuery({ categoryId: '4' });
  const { data: innerContent5, isLoading: isLoading5 } = useFaqQuery({ categoryId: '5' });
  const { data: innerContent6, isLoading: isLoading6 } = useFaqQuery({ categoryId: '6' });
  const { data: innerContent7, isLoading: isLoading7 } = useFaqQuery({ categoryId: '7' });
  const { data: innerContent8, isLoading: isLoading8 } = useFaqQuery({ categoryId: '8' });

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const query = useQuery();

  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openForgot, setOpenForgot] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openRegister, setOpenRegister] = useState<boolean>(false);

  const router = useIonRouter();

  const { data: allCategories, isLoading: allCategoriesLoading } = useGetAllFaqCategoriesQuery();
  const [trigger, { data: searchData, isFetching: searchDataLoading }] = useLazyFaqQuery();

  const [search, setSearch] = useState<string>('');

  const debouncedSearch = useDebounce(search, 500);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    if (query.get('id')) {
      history.replace({ state: { scrollY: e.target.detail.scrollTop }, search: history.location.search });
    } else {
      history.replace({ state: { scrollY: e.target.detail.scrollTop } });
    }
  }

  useEffect(() => {
    if (debouncedSearch !== '') trigger({ keyword: debouncedSearch });
  }, [debouncedSearch, trigger]);

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <>
            <Box>
              {location.pathname.includes('/faq') && (
                <Helmet>
                  <title>Συχνές ερωτήσεις | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                  <meta
                    name="description"
                    content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                  />
                  <meta name="og:name" content="Pigogo" />
                  <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
                  <meta
                    name="og:image"
                    content="https://stg.pigogo.gr/storage/pigogo-socialshare/pigogo-socialshare.jpg"
                  />
                </Helmet>
              )}
              <CustomHeader />
              {downMd && (
                <IonHeader class="ion-no-border">
                  <IonToolbar
                    style={{
                      '--background': '#FFFFFF',
                      '--border-width': 0,
                      paddingTop: '0 + --ion-safe-area-top',
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                      <IonButtons slot="start">
                        <IonBackButton
                          className="backBtn"
                          text=""
                          style={{
                            '--color': '#313D53',
                            '--padding-start': 0,
                            '--padding-end': 0,
                            '--icon-font-size': '24px',
                            '--border-radius': 0,
                            '--min-height': '24px',
                            '--min-width': '24px',
                            width: '24px',
                            height: '24px',
                            minWidth: '24px',
                            maxHeight: '24px',
                            display: 'inline-flex',
                          }}
                          icon={backArrow}
                          defaultHref={'/'}
                        />
                      </IonButtons>
                      <Typography component="h2" variant="body2" color={'#313D53'}>
                        Συχνές ερωτήσεις
                      </Typography>
                      <Box minWidth={'24px'}></Box>
                    </Box>
                  </IonToolbar>
                </IonHeader>
              )}
            </Box>
            <TopBanner search={search} setSearch={setSearch} />
            <Box
              sx={{
                marginInline: 'auto',
                marginTop: mobile ? '-16px' : 0,
                width: '1088px',
                maxWidth: '100%',
              }}
            >
              <AnswerSection
                searchData={searchData}
                search={debouncedSearch}
                searchDataLoading={searchDataLoading}
                allCategoriesLoading={allCategoriesLoading}
                allCategories={allCategories ? allCategories.data : []}
              />
            </Box>
            <Box pt={mobile ? 0 : 4} px={downMd ? (mobile ? 2 : 4) : 0}>
              <SupportSection openLoginModal={() => setOpenLogin(true)} />
            </Box>
            <ForgotModal isOpen={openForgot} setOpen={setOpenForgot} success={setOpenSuccess} />
            <SuccessPasswordModal
              title={'Ευχαριστούμε πολύ!'}
              subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
              open={openSuccess}
              handleClose={setOpenSuccess}
            />
            <RegisterModal
              isOpen={openRegister}
              setOpen={setOpenRegister}
              setLoginModal={setOpenLogin}
              userDismiss={() => {
                setOpenLogin(false);
                setOpenRegister(false);
              }}
              successCallback={() => {
                setOpenLogin(false);

                if (openRegister) {
                  setTimeout(() => {
                    router.push(`/create-claim`);
                  }, 500);
                }
              }}
            />
            <LoginModal
              message={''}
              isOpen={openLogin}
              setLoginModal={(value: boolean) => {
                setOpenLogin(value);
              }}
              dismiss={() => {
                setOpenLogin(false);

                if (openLogin) {
                  setTimeout(() => {
                    router.push(`/create-claim`);
                  }, 500);
                }
              }}
              userDismiss={() => {
                setOpenLogin(false);
              }}
              openRegisterModal={() => {
                setOpenRegister(true);
                setOpenLogin(false);
              }}
              openForgotModal={() => setOpenForgot(true)}
            />
            {isDesktop && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default FAQPage;
