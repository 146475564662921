import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Pagination from '../../components/Stores/Pagination';
import { useLazySearchQuery } from '../../redux/api/queries/searchQueries';
import StoreCards from '../../components/Stores/StoreCards';
import { AllShop } from '../../models/AllShop';
import {
  useDeleteSearchFavouriteMutation,
  usePostSearchFavouriteMutation,
} from '../../redux/api/mutations/favouritesMutation';
import useAppendData from '../../hooks/useAppendData';
import { useDispatch } from 'react-redux';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import PromoBanner from '../../components/Stores/PromoBanner';
import FiltersSection from '../../components/Stores/FiltersSection';
import PigogoFooter from '../../components/PigogoFooter';
import CustomContainer from '../../components/CustomContainer';
import Layout from '../../components/Navigation/Layout';
import useQuery from '../../hooks/useQuery';
import NoResultsPage from '../NoResultsPage';
import backArrow from '../../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { GenericRequest } from '../../models/GenericRequest';
import { Helmet } from 'react-helmet-async';
import CustomHeader from '../../components/CustomHeader';
import { useGetUserDataQuery } from '../../redux/api/queries/userQueries';
import { useLazyFavouritesListingQuery } from '../../redux/api/queries/favouritesQuery';
import PigogoSocialShare from '../../assets/jpg/pigogo-socialshare.jpg';

const SearchStores = () => {
  const params = useQuery();
  const history = useHistory();
  const router = useIonRouter();
  const dispatch = useDispatch();

  const path = router.routeInfo.pathname;

  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [keyword, setKeyword] = useState<string | null>(params.get('keyword'));

  const [result, setResult] = useState<AllShop[]>([]);

  const appendData = useRef<boolean>(false);
  const infiniteEvent = useRef<HTMLIonInfiniteScrollElement | null>(null);

  const [trigger, { data: searchData, isLoading, isFetching, isError: errorSearch, status }] = useLazySearchQuery();

  const [postFavourite, { isError: postFavouriteFailed, isSuccess: postFavouriteSucceeded, reset: resetPostFav }] =
    usePostSearchFavouriteMutation();
  const [
    deleteFavourite,
    { isError: deleteFavouriteFailed, isSuccess: deleteFavouriteSucceeded, reset: resetDeleteFav },
  ] = useDeleteSearchFavouriteMutation();

  const [postAppend, setPostAppend] = useState<number[]>([]);
  const [deleteAppend, setDeleteAppend] = useState<number[]>([]);

  const [favShops, setFavShops] = useState<number[]>([]);
  const [unfavShops, setUnfavShops] = useState<number[]>([]);

  const [response, setResponse] = useState<GenericRequest<AllShop[]> | undefined>(undefined);
  const finalData = useAppendData(response?.data, appendData.current);

  const [fetchedFromCache, setFetchedFromCache] = useState<boolean>(false);
  const [fetchPrevious, setFetchPrevious] = useState<boolean>(false);

  const [isLoadingShops, setIsLoadingShops] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const [sort, setSort] = useState<string>('rl');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState<string[]>([]);

  const [page, setPage] = useState<number>(1);
  const [promo, setPromo] = useState<string | undefined>(undefined);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [resetFilters, setResetFilters] = useState<boolean>(false);

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const { data: userData } = useGetUserDataQuery();
  const [getFavs, { data: favourites, isLoading: loadFavourites }] = useLazyFavouritesListingQuery();

  useIonViewWillEnter(() => {
    if (!mdDown) return;

    if (!localStorage.getItem(`search`)) {
      setFetchPrevious(false);
      setResetFilters(true);
      return;
    }

    const searchParams = JSON.parse(localStorage.getItem('search-parameters') ?? '{}');
    const searchParam = Object.assign({}, searchParams)[history.location.pathname];

    if (searchParam) {
      searchParams[history.location.pathname] = '';
      localStorage.setItem('search-parameters', JSON.stringify(searchParams));
      history.replace(`${history.location.pathname}${searchParam}`, {
        scrollY: (history.location.state as any)?.scrollY ?? 0,
        fetchPrevious: true,
      });
    }
  });

  useEffect(() => {
    const userFavs: number[] = [];
    if (userData) {
      const userFavorites = getFavs({});
      userFavorites.then(async (res) => {
        result.forEach((store) => {
          if (res.data.data[`${store.id}`]) {
            userFavs.push(store.id);
          }
        });
        setFavShops(userFavs);
      });
    }
  }, [userData, result]);

  const setParameters = async (params: URLSearchParams) => {
    const pageParam = params.get('page');
    const sortParam = params.get('sort');
    const discountParam = params.get('discountTypes');
    const categoryParam = params.get('categories');
    const promoParam = path.includes('/promo') ? path.split('/').slice(-1)[0] : null;

    await Promise.all([
      new Promise((res) => {
        res(setPage(pageParam ? Number(pageParam) : 1));
      }),
      new Promise((res) => {
        res(setSort(sortParam ?? 'rl'));
      }),
      new Promise((res) => {
        res(categoryParam !== '' && setSelectedCategories(categoryParam?.split(',') ?? []));
      }),
      new Promise((res) => {
        res(discountParam !== '' && setSelectedDiscounts(discountParam?.split(',') ?? []));
      }),
      new Promise((res) => {
        res(setPromo(promoParam ?? undefined));
      }),
    ]);
  };

  useIonViewDidEnter(() => {
    const params = new URLSearchParams(history.location.search);

    const setParametersAsync = async (params: URLSearchParams) => {
      await setParameters(params);
    };

    !fetchPrevious && setParametersAsync(params);
    setInitialized(true);

    if (!mdDown) return;
    const scrollPos = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
    const scrollPosition = Object.assign({}, scrollPos)[history.location.pathname];

    setTimeout(() => {
      !!scrollPosition && contentRef && contentRef.current && contentRef.current.scrollToPoint(0, scrollPosition, 10);
    }, 300);
  });

  useIonViewWillLeave(() => {
    setFetchPrevious(true);
  });

  function handleScrollEnd(e: any) {
    history.replace(`${router.routeInfo.pathname}?${params}`, {
      scrollY: e.target.detail.scrollTop,
      fetchPrevious: (history.location.state as any)?.fetchPrevious,
    });
  }

  useEffect(() => {
    if (isLoadingShops) return;

    const scrollPos = JSON.parse(localStorage.getItem('scroll-positions') ?? '{}');
    const scrollPosition = Object.assign({}, scrollPos)[history.location.pathname];

    const scroll = mdDown ? scrollPosition ?? 0 : (history.location.state as any)?.scrollY;
    setTimeout(
      () => {
        (history.location.state as any)?.scrollY &&
          contentRef &&
          contentRef.current &&
          contentRef.current.scrollToPoint(0, scroll, 10);
      },
      mdDown ? 300 : 600,
    );
  }, [isLoadingShops]);

  useEffect(() => {
    if (!initialized) return;
    const setInitializedAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setInitialized(value));
      });
    };

    const setParametersAsync = async (params: URLSearchParams) => {
      await setParameters(params);
    };

    const params = new URLSearchParams(history.location.search);
    if (
      (params.get('page') ?? '1') !== (page.toString() ?? '1') ||
      (params.get('sort') ?? 'rl') !== (sort.toString() ?? 'rl') ||
      (params.get('discountTypes') ?? '') !== (selectedDiscounts ?? []).join(',') ||
      (params.get('categories') ?? '') !== (selectedCategories ?? []).join(',')
    ) {
      setInitializedAsync(false);
      setParametersAsync(params);
      setInitializedAsync(true);

      return;
    }

    const triggerFetchAsync = async () => {
      await new Promise((res) => {
        res(
          triggerFetch(
            keyword,
            {
              sort,
              discount: selectedDiscounts.join(','),
              category: selectedCategories.join(','),
            },
            page.toString() ?? null,
          ),
        );
      });
    };

    const setIsLoadingShopsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingShops(value));
      });
    };

    !loadingMore && !fetchPrevious && setIsLoadingShopsAsync(true);
    triggerFetchAsync();
    setTimeout(() => {
      setIsLoadingShopsAsync(false);
    }, 3000);
  }, [initialized, selectedCategories, selectedDiscounts, sort, page, keyword]);

  useEffect(() => {
    if (!resetFilters || history.location.search !== '') return;

    const params = new URLSearchParams('');
    const setParametersAsync = async (params: URLSearchParams) => {
      await setParameters(params);
    };

    const setIsLoadingShopsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingShops(value));
      });
    };

    const setInitializedAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setInitialized(value));
      });
    };

    setInitializedAsync(false);
    setIsLoadingShopsAsync(true);
    setParametersAsync(params);

    setInitializedAsync(true);
    setResetFilters(false);
  }, [resetFilters]);

  useEffect(() => {
    setFavShops(result.filter((elem: any) => !!elem.user_favourite).map((elem: any) => elem.id));
    setUnfavShops(result.filter((elem: any) => !elem.user_favourite).map((elem: any) => elem.id));
  }, [result]);

  useEffect(() => {
    if (!favShops.length || !result.length) {
      return;
    }

    const cacheKey = `cursor=${page ?? '1'};limit=${48}|sort=${sort ?? 'rl'};discount=${
      selectedDiscounts.join(',') ?? ''
    };category=${selectedCategories.join(',') ?? ''};keyword=${keyword ?? ''}`;

    let cachedData = JSON.parse(JSON.parse(localStorage.getItem('search') ?? '{}')[cacheKey] ?? '{}');

    cachedData = {
      ...cachedData,
      data: cachedData?.data?.map((elem: any) => {
        return {
          ...elem,
          user_favourite: favShops.includes(elem?.id),
        };
      }),
    };

    if (cachedData.length) {
      localStorage.setItem('search', JSON.stringify({ [cacheKey]: JSON.stringify(cachedData) }));
    }
  }, [favShops]);

  const setFavourite = async (shop: AllShop, index: number) => {
    if (!shop) {
      return;
    }

    if (!favShops.some((elem) => elem === shop.id) && !postAppend.includes(index)) {
      setPostAppend([...postAppend, index]);

      setFavShops([...favShops, shop?.id]);
      setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));

      const data: any = await postFavourite({ shop_id: shop.id, notifications: 1 });
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής προσθήκη αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setUnfavShops([...unfavShops, shop?.id]);
        setFavShops(favShops.filter((elem) => elem !== shop?.id));
      }

      const idx = postAppend.findIndex((elem) => elem === index);
      setPostAppend(postAppend.splice(idx, idx).splice(index, index));
    } else if (favShops.some((elem) => elem === shop.id) && !deleteAppend.includes(index)) {
      setDeleteAppend([...deleteAppend, index]);

      setUnfavShops([...unfavShops, shop?.id]);
      setFavShops(favShops.filter((elem) => elem !== shop?.id));

      const data: any = await deleteFavourite(shop.id);
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής αφαίρεση αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShops([...favShops, shop?.id]);
        setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));
      }

      const idx = deleteAppend.findIndex((elem) => elem === index);
      setDeleteAppend(deleteAppend.splice(idx, idx).splice(index, index));
    }

    return;
  };

  useEffect(() => {
    if (errorSearch) {
      setLoadingMore(false);
      const params = { value: true, msg: 'Ανεπιτυχής φόρτωση δεδομένων αναζήτησης!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorSearch]);

  useEffect(() => {
    const setIsLoadingShopsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingShops(value));
      });
    };
    setIsLoadingShopsAsync(false);
  }, [fetchedFromCache]);

  useEffect(() => {
    setResult(finalData);
    const setIsLoadingShopsAsync = async (value: boolean) => {
      await new Promise((res) => {
        res(setIsLoadingShops(value));
      });
    };
    setIsLoadingShopsAsync(false);
    appendData.current = false;
    if (infiniteEvent.current) {
      infiniteEvent.current.complete();
    }
    infiniteEvent.current = null;
  }, [finalData]);

  useEffect(() => {
    if (status === 'fulfilled') {
      appendData.current = false;
      if (infiniteEvent.current) {
        infiniteEvent.current.complete();
      }
      infiniteEvent.current = null;
    }
  }, [status]);

  const prepareCategories = useMemo(() => {
    if (response && response.categories) {
      return response.categories.map((item: any) => ({
        id: item.id,
        label: item.label,
        slugName: item.slug.name,
        slug: { name: item.slug.name },
      }));
    }
    return [];
  }, [response?.categories]);

  const triggerFetch = async (
    keyword: string | null,
    filters: { sort: string | null; category: string | null; discount: string | null },
    currentCursor: string | null,
  ) => {
    const cacheKey = `cursor=${currentCursor ?? '1'};limit=${48}|sort=${filters.sort ?? 'rl'};discount=${
      filters.discount ?? ''
    };category=${filters.category ?? ''};keyword=${keyword ?? ''}`;

    if (
      cacheKey === Object.keys(JSON.parse(localStorage.getItem('search') ?? '{}'))[0] &&
      (history.location.state as any)?.fetchPrevious
    ) {
      const cacheData = await JSON.parse(JSON.parse(localStorage.getItem('search') ?? '{}')[cacheKey]);
      await new Promise((res) => {
        res(
          cacheData?.data.map((shop: AllShop) => {
            const shopImage = new Image();
            shopImage.src = shop.image as string;
            return shopImage;
          }),
        );
      });
      setResponse(cacheData);
    } else {
      const data = await trigger({
        limit: 48,
        sort: filters.sort ?? 'rl',
        nextCursor: currentCursor ?? undefined,
        discount: filters.discount ?? undefined,
        category: filters.category ?? undefined,
        keyword: keyword ?? '',
      });

      setResponse(data?.data);

      if (
        Object.keys(JSON.parse(localStorage.getItem('search') ?? '{}'))[0]?.split('|')[1] === cacheKey.split('|')[1]
      ) {
        const shopsWithFav = [
          ...(result ?? []),
          ...((result ?? []).findIndex((e: any) => e?.id === (data?.data?.data ?? [])[0]?.id) === -1
            ? data?.data?.data ?? []
            : []),
        ];

        const final = { ...data?.data, data: shopsWithFav };

        if (history.location.pathname === '/search') {
          if (final.data.length) {
            localStorage.setItem('search', JSON.stringify({ [cacheKey]: JSON.stringify(final) }));
          }
        }
      } else {
        const shopsWithFav = data?.data?.data ?? [];

        const final = { ...data?.data, data: shopsWithFav };

        if (history.location.pathname === '/search') {
          if (final.data.length) {
            localStorage.setItem('search', JSON.stringify({ [cacheKey]: JSON.stringify(final) }));
          }
        }
      }
    }
    history.replace(`${router.routeInfo.pathname}?${params}`, {
      scrollY: (history.location.state as any)?.scrollY,
      fetchPrevious: true,
    });
  };

  const loadData = async (ev?: any) => {
    setLoadingMore(true);
    appendData.current = true;
    const newCursor = response?.nextCursor;
    if (newCursor) {
      setPage(Number(newCursor));
      params.set('page', newCursor);
      history.replace(`${router.routeInfo.pathname}?${params}`);
    }
    if (ev) {
      infiniteEvent.current = ev.target;
    }
  };

  const renderDesktop = () => {
    return (
      <Box pb={18}>
        <StoreCards
          loading={isLoadingShops || (isFetching && !loadingMore)}
          setLoading={setIsLoadingShops}
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          dataStores={result ?? undefined}
          setFavourite={setFavourite}
          favouriteStores={favShops}
          unfavouriteStores={unfavShops}
          fetchedDataFromCache={fetchedFromCache}
        />
        {!isLoadingShops && (
          <Pagination
            currentCount={result ? result.length : 0}
            total={response && response.totalCount ? response.totalCount : 0}
            hasNextPage={!!response?.nextCursor}
            nextPage={response?.nextCursor}
            loadMore={loadData}
          />
        )}
      </Box>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <StoreCards
          loading={isLoadingShops || (isFetching && !loadingMore)}
          setLoading={setIsLoadingShops}
          loadingMore={loadingMore}
          setLoadingMore={setLoadingMore}
          dataStores={result ?? undefined}
          setFavourite={setFavourite}
          favouriteStores={favShops}
          unfavouriteStores={unfavShops}
          fetchedDataFromCache={fetchedFromCache}
        />
        {!isLoadingShops && result.length > 0 && (
          <Box mb={8}>
            <Pagination
              currentCount={result ? result.length : 0}
              total={response && response.totalCount ? response.totalCount : 0}
              hasNextPage={!!response?.nextCursor}
              nextPage={response?.nextCursor}
              loadMore={loadData}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <IonPage>
      <IonContent scrollEvents={!isFetching} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout setSearchKeyword={setKeyword} searchKeyword={keyword ?? undefined}>
          <>
            <Box>
              <CustomHeader />
              {mdDown && (
                <IonHeader class="ion-no-border">
                  <IonToolbar
                    style={{
                      '--background': '#FFFFFF',
                      '--border-width': 0,
                      paddingTop: '0 + --ion-safe-area-top',
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                      <IonButtons slot="start">
                        <IonBackButton
                          className="backBtn"
                          text=""
                          style={{
                            '--color': '#313D53',
                            '--padding-start': 0,
                            '--padding-end': 0,
                            '--icon-font-size': '24px',
                            '--border-radius': 0,
                            '--min-height': '24px',
                            '--min-width': '24px',
                            width: '24px',
                            height: '24px',
                            minWidth: '24px',
                            maxHeight: '24px',
                            display: 'inline-flex',
                          }}
                          icon={backArrow}
                          defaultHref={'search-stores'}
                        />
                      </IonButtons>
                      <Typography component="h2" variant="body2" color={'#313D53'}>
                        Αποτελέσματα
                      </Typography>
                      <Box minWidth={'24px'}></Box>
                    </Box>
                  </IonToolbar>
                </IonHeader>
              )}
              {location.pathname === '/search' && (
                <Helmet>
                  <title>Αποτελέσματα αναζήτησης | Pigogo - Επιστροφή & προσφορές</title>
                  <meta
                    name="description"
                    content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                  />
                  <meta name="og:name" content="Pigogo" />
                  <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
                  <meta name="og:image" content={PigogoSocialShare} />
                </Helmet>
              )}
            </Box>
            <CustomContainer>
              <Box>
                <PromoBanner />

                {mdUp && (
                  <Box paddingTop={mdDown ? 3 : 8} mb={4}>
                    <Typography variant={'h4'} color={'#313D53'} component="h2">
                      {`Αποτελέσματα αναζήτησης για "${keyword}"`}
                    </Typography>
                  </Box>
                )}

                {smDown && (
                  <Typography
                    variant={'body2'}
                    component="h2"
                    color={'#313D53'}
                    sx={{ marginTop: '2px', marginBottom: '16px' }}
                  >
                    {`Αποτελέσματα αναζήτησης για "${keyword}"`}
                  </Typography>
                )}
                <FiltersSection
                  searchCategories={prepareCategories}
                  searchKeyword={params.get('keyword') ?? ''}
                  totalResults={response?.totalCount ?? 0}
                  selectedCategories={selectedCategories}
                  selectedDiscounts={selectedDiscounts}
                  sort={sort}
                  setSelectedCategories={setSelectedCategories}
                  setSelectedDiscounts={setSelectedDiscounts}
                  page={page}
                  setPage={setPage}
                  setSort={setSort}
                  setLoading={setIsLoadingShops}
                />
                {!(response?.totalCount === 0 && !isLoading) && (
                  <Box>
                    {!mdDown && renderDesktop()}
                    {mdDown && renderMobile()}
                  </Box>
                )}
                {response?.totalCount === 0 && !isLoading && <NoResultsPage />}
              </Box>
            </CustomContainer>
            {!mdDown && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default SearchStores;
