import React, { FC, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import PigogoSymbolWrapper from '../PigogoSymbolWrapper';
import TellAFriendTerms from './TellAFriend/TellAFriendTerms';
import ShareWithSocial from './TellAFriend/ShareWithSocial';
import ShareWithEmail from './TellAFriend/ShareWithEmail';
import ShareWithLink from './TellAFriend/ShareWithLink';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../../assets/jpg/pigogo-socialshare.jpg';

const TellAFriend = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      {smDown && (
        <PigogoSymbolWrapper typeOfWrapper="tell-a-friend" mx={-2}>
          <Box
            py={4}
            px={2}
            gap={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={'flex-start'}
            sx={{ maxHeight: '352px', width: '100%' }}
          >
            <Typography variant="largeB" color={'#1D2532'} component="h3">
              Προσκάλεσε τους φίλους σου και κέρδισε 5€!
            </Typography>
            <Typography
              fontWeight={200}
              fontSize={'14px'}
              color={'#1D2532'}
              lineHeight={'20px'}
              textAlign={'start'}
              variant="body2MediumL"
              component="p"
            >
              Στείλε το Pigogo στους φίλους σου και μόλις εκείνοι εγγραφούν και κερδίσουν επιστροφή 3€, εσύ θα κερδίσεις
              από 5€ για κάθε φίλο σου!
            </Typography>
          </Box>
        </PigogoSymbolWrapper>
      )}

      <Box display="flex" pb={smDown ? '40px' : '136px'} flexDirection="column" gap={4} pt={mdDown ? 4 : 0}>
        {location.pathname === '/user/tell-a-friend' && (
          <Helmet>
            <title>Tell a friend - Ο λογαριασμός μου | Pigogo</title>
            <meta
              name="description"
              content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
            />
            <meta name="og:name" content="Pigogo" />
            <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
            <meta name="og:image" content={PigogoSocialShare} />
          </Helmet>
        )}
        {!smDown && (
          <Box display="flex" flexDirection="column" gap="4px" p={3} border="2px solid #EAECEE" borderRadius="24px">
            <Typography variant="body1" color={'#1D2532'} component="h3">
              Προσκάλεσε τους φίλους σου και κέρδισε 5€
            </Typography>
            <Typography variant="subtitle1SmallL" component="p" color={'#313D53'}>
              Στείλε το Pigogo στους φίλους σου και μόλις εκείνοι εγγραφούν και κερδίσουν επιστροφή 3€, εσύ θα κερδίσεις
              από 5€ για κάθε φίλο σου!
            </Typography>
          </Box>
        )}
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <ShareWithLink />
          <ShareWithEmail />
          {/*<ShareWithSocial />*/}
          <TellAFriendTerms />
        </Box>
      </Box>
    </Box>
  );
};

export default TellAFriend;
