import React, { useRef } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { PigogoEntryPoint, theme } from 'components';
import Layout from '../components/Navigation/Layout';
import PigogoFooter from '../components/PigogoFooter';
import ErrorIntro from '../components/Error/ErrorIntro';
import CustomContainer from '../components/CustomContainer';
import { ReactComponent as Error } from '../assets/svgs/error.svg';
import backArrow from '../assets/svgs/back_arrow.svg';
import SupportSection from '../components/FAQ/SupportSection';
import CustomHeader from '../components/CustomHeader';
import { Helmet } from 'react-helmet-async';
import PigogoSocialShare from '../assets/jpg/pigogo-socialshare.jpg';

const PageNotFound = () => {
  const router = useIonRouter();

  const desktop = isPlatform('desktop');
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    contentRef.current && contentRef.current.scrollToTop();
  });

  return (
    <IonPage>
      <IonContent ref={contentRef}>
        <Layout>
          <>
            <Box>
              <CustomHeader />
              {mdDown && (
                <IonHeader class="ion-no-border">
                  <IonToolbar style={{ '--background': '#FFFFFF', '--border-width': 0, padding: 0 }}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                      <IonButtons slot="start">
                        <IonBackButton
                          className="backBtn"
                          text=""
                          style={{
                            '--color': '#313D53',
                            '--padding-start': 0,
                            '--padding-end': 0,
                            paddingTop: '0 + --ion-safe-area-top',
                            '--icon-font-size': '24px',
                            '--border-radius': 0,
                            '--min-height': '24px',
                            '--min-width': '24px',
                            width: '24px',
                            height: '24px',
                            minWidth: '24px',
                            maxHeight: '24px',
                            display: 'inline-flex',
                          }}
                          icon={backArrow}
                          defaultHref={'/'}
                        />
                      </IonButtons>
                      <Typography component="h2" variant="body2" color={'#313D53'}>
                        Η σελίδα που ψάχνεις δεν υπάρχει
                      </Typography>
                      <Box minWidth={'24px'}></Box>
                    </Box>
                  </IonToolbar>
                </IonHeader>
              )}
              {location.pathname === '/404-not-found' && (
                <Helmet>
                  <title>404 - Η σελίδα δεν βρέθηκε! | Pigogo</title>
                  <meta
                    name="description"
                    content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                  />
                  <meta name="og:name" content="Pigogo" />
                  <meta name="og:description" content="Pigogo: Επιστροφή χρημάτων για κάθε online αγορά σου" />
                  <meta name="og:image" content={PigogoSocialShare} />
                </Helmet>
              )}
            </Box>
            <ErrorIntro />
            <CustomContainer>
              <Box display={'flex'} flexDirection={'column'} mt={'130px'} mb={16}>
                <Box display={'flex'} justifyContent={'center'}>
                  <Error />
                </Box>
                <Divider sx={{ marginTop: '18px', borderColor: '#F7BA97', borderBottomWidth: '2px' }} />
              </Box>
              <SupportSection />
            </CustomContainer>
            {!mdDown && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default PageNotFound;
