import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Skeleton,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { Down } from '../assets';
import theme from '../theme';

type NavAccordionProps<C extends React.ElementType> = {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  titleProps?: TypographyProps<C, { component?: C }>;
  noBorder?: boolean;
};

const StyledAccordion = styled(Accordion)<NavAccordionProps<React.ElementType> & AccordionProps>((props) => ({
  '&.MuiAccordion-root': {
    margin: 0,
    padding: 0,
    minHeight: 0,
    borderTop: props.noBorder ? '' : '1px solid #EAECEE',
    boxSizing: 'border-box',
    transition: 'border .3s ease',
    '&:before': {
      display: 'none',
    },
    'not(:last-child)': {
      borderBottom: props.noBorder ? '' : '1px solid #EAECEE',
    },
  },
  '.accordeon--noBorder &.MuiAccordion-root': {
    borderTop: 0,
    borderRadius: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const StyledTypography = styled(Typography)<NavAccordionProps<React.ElementType> & TypographyProps>(({ theme }) => ({
  '&.MuiTypography-root': {
    maxWidth: '696px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '295px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '376px',
    },
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)<NavAccordionProps<React.ElementType> & AccordionSummaryProps>(
  ({ theme }) => ({
    '&.MuiAccordionSummary-root': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '24px 0 16px 0',
    },
    '.accordeonP8 &.MuiAccordionSummary-root': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '&.Mui-expanded': {
      // paddingBottom: '0px',
    },
  }),
);

const StyledAccordionDetails = styled(AccordionDetails)<NavAccordionProps<React.ElementType> & AccordionDetailsProps>(
  () => ({
    '&.MuiAccordionDetails-root': {
      margin: 0,
      padding: '8px 0px 16px 0px',
    },
  }),
);

const StyleSkeleton = styled(Skeleton)(() => ({
  background: '#EAECEE',
  borderRadius: '16px',
  [theme.breakpoints.down('sm')]: {
    width: '250px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '620px',
  },
  [theme.breakpoints.up('md')]: {
    width: '600px',
  },
}));

const PigogoAccordion: FC<NavAccordionProps<React.ElementType> & AccordionProps> = ({
  title,
  loading,
  disabled,
  titleProps,
  noBorder,
  children,
  expanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    !disabled && setIsExpanded(expanded);
  };

  return (
    <StyledAccordion disableGutters elevation={0} expanded={isExpanded} onChange={handleChange}>
      <StyledAccordionSummary expandIcon={<Down />}>
        <StyledTypography {...titleProps}>
          {loading ? <StyleSkeleton variant="rectangular" animation={'wave'} /> : title}
        </StyledTypography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default PigogoAccordion;
