import React, { FC, useState } from 'react';
import MenuItemCategory from './MenuItemCategory';
import { ShopCategory } from '../../models/ShopCategory';
import { Box, FormControl, Select, styled, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '280px',
      borderRadius: 8,
      marginTop: '8px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.02), 0px 12px 25px rgba(0, 0, 0, 0.08)',
      overflow: 'hidden',
    },
  },
};

const StyledSelect = styled(Select)({
  [`& fieldset`]: {
    borderRadius: '56px',
  },
  '& .MuiSvgIcon-root': {
    right: '24px',
  },
  '& [aria-expanded="true"] ~ .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
  },
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: '12px',
    paddingLeft: '24px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
});

interface Props {
  label: string;
  error?: boolean;
  offers?: boolean;
  fullWidth?: boolean;
  searchKeyword: string;
  width?: number | string;
  parentCategory?: string;
  options: ShopCategory[];
  size?: 'small' | 'medium';
  categoriesChecked: { slugName: string; label: string }[];
  setCategoriesChecked: (categoriesChecked: { slugName: string; label: string }[]) => void;
}

const ArrowDown = () => (
  <SvgIcon sx={{ position: 'absolute', top: 'calc(50% - 0.5em)', pointerEvents: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z"
      fill="#838B98"
    />
  </SvgIcon>
);

const MultipleDropdown: FC<Props> = ({
  label,
  error,
  fullWidth,
  width = 304,
  options = [],
  searchKeyword,
  offers = false,
  size = 'medium',
  parentCategory,
  categoriesChecked,
  setCategoriesChecked,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <FormControl error={error} variant="outlined" size={size} sx={{ width: fullWidth ? '100%' : width }}>
      <style>
        {`
        .MuiMenu-list {
          padding-top: 0;
          padding-bottom: 0;
          max-height: 280px;
          overflow-y: auto;
          border-radius: 8px;
        }
        `}
      </style>
      <StyledSelect
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        multiple
        displayEmpty
        IconComponent={ArrowDown}
        value={categoriesChecked.map((category) => category.label)}
        MenuProps={MenuProps}
        renderValue={(selected: any) => {
          if (selected.length !== 0) {
            return (
              <Box py={0.5} display="flex" alignItems="start" justifyContent="start">
                <Typography variant={'subtitle2'} fontWeight={'600'} sx={{ letterSpacing: '0' }}>
                  {selected.length === 1 ? `${selected.length} επιλεγμένη` : `${selected.length} επιλεγμένες`}
                </Typography>
              </Box>
            );
          }
          return (
            <Box py={0.5} display="flex" alignItems="start" justifyContent="start">
              <Typography variant={'subtitle2'} color={'#313D53'} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          );
        }}
      >
        {options.map((option, index) => (
          <MenuItemCategory
            key={index}
            offers={offers}
            subCategory={option}
            searchKeyword={searchKeyword}
            parentCategory={parentCategory}
            selectedCategories={categoriesChecked}
            setSelectedCategories={setCategoriesChecked}
          />
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default MultipleDropdown;
